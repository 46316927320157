import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, { useRef } from "react";
import ChroniclesIllus from "@/assets/icons/wrapped/chronicles.svg";
import classNames from "classnames";
import { getIntroAnimation1, getIntroAnimation2 } from "@/assets/js/animations/wrapped/intro";
import Text from "gsap/dist/TextPlugin";
import { useListener } from "@/components/hooks/useListener";
import { YearWrappedResponse } from "@/assets/interfaces";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(Text);

const rings = new Array(4).fill(0);
interface Props extends YearWrappedResponse {}

const IntroCard: React.FC<Props> = ({ store, is_shared }) => {
  const container = useRef();
  const tlRef = useRef<gsap.core.Timeline>();

  useListener("toggle-play-gsap", () => {
    if (tlRef.current) {
      if (tlRef.current.paused()) tlRef.current.play();
      else tlRef.current.pause();
    }
  });

  useGSAP(
    () => {
      const tl = gsap.timeline();
      tlRef.current = tl;

      tl.set(".text-row", { y: "20", opacity: 0 });
      tl.set(".header", { y: "20", opacity: 0 });
      tl.set(".trinkets", { opacity: 0 });
      tl.set(".part-2", { display: "none" });

      getIntroAnimation1(tl, rings);
      getIntroAnimation2(tl, rings, is_shared);
    },
    { scope: container }
  );

  const textRows = is_shared
    ? [
        <span key={0}>Welcome to my 2024 Catlog</span>,
        <span key={1}>Chronicle. 2024 was a very </span>,
        <span key={2}>busy year! Ready to explore? </span>,
      ]
    : [
        <span key={0}>Welcome to your 2024 Catlog</span>,
        <span key={1}>Chronicle! You’ve been a busy business </span>,
        <span key={2}>butterfly, haven’t you? </span>,
      ];

  return (
    <div
      ref={container}
      className="relative overflow-hidden flex flex-col items-center justify-center w-full h-full bg-primary-400"
    >
      <div className="w-full absolute overflow-hidden left-0 top-0 h-full">
        {rings.map((r, i) => (
          <ChroniclesIllus
            key={i}
            className={classNames("absolute abs-center c-ring", `c-ring-${i}`)}
            width={300 + i * 80}
            height={300 + i * 80}
          />
        ))}
      </div>
      {/* prettier-ignore */}
      <svg className="trinkets absolute z-0  w-full abs-center" viewBox="0 0 1080 1160" fill="none" >
        <path d="M200.139 470.298C195.628 474.32 188.183 474.085 184.055 469.461C179.92 465.056 180.268 457.506 184.785 453.264C189.416 448.917 196.967 449.265 201.201 454.002C205.322 458.845 204.762 466.17 200.139 470.298Z" fill="#FD4341"/>
        <path d="M585.998 909.079C581.487 913.101 574.042 912.866 569.914 908.242C565.779 903.838 566.127 896.287 570.645 892.046C575.275 887.699 582.826 888.047 587.061 892.783C591.182 897.626 590.622 904.951 585.998 909.079Z" fill="#1093FF"/>
        <path d="M818.313 407.591C822.554 412.109 822.319 419.553 817.915 423.688C813.291 427.817 805.952 427.694 801.817 423.29C797.47 418.659 797.818 411.108 802.555 406.874C806.952 402.958 814.284 403.299 818.313 407.591Z" fill="#A920FF"/>
        <path d="M993.221 627.072C990.146 623.796 987.071 620.521 984.103 617.359C980.929 613.752 980.926 610.354 984.321 606.954C988.952 602.607 993.47 598.366 998.1 594.019C1000.81 591.474 1000.92 591.368 998.612 588.227C994.518 582.508 990.425 576.79 986.331 571.071C982.973 566.362 983.268 563.961 987.447 560.038C991.852 555.903 996.256 551.768 1000.66 547.633C1003.26 545.194 1003.37 545.088 1001.17 542.059C997.072 536.341 992.979 530.622 988.885 524.904C985.315 519.969 985.75 516.585 990.685 513.015C997.081 508.285 1003.36 503.662 1009.87 499.045C1010.76 498.416 1011.66 497.786 1012.77 497.383C1015.42 496.59 1018.57 497.676 1020.14 499.808C1021.61 502.046 1021.5 505.549 1019.56 507.79C1016.73 510.878 1013.04 513.063 1009.67 515.587C1008.1 516.853 1006.53 517.899 1004.96 519.164C1002.93 520.854 1002.8 521.398 1004.28 523.417C1006.69 526.891 1009.21 530.258 1011.73 533.626C1013.42 535.871 1015.2 538.228 1016.89 540.473C1020.14 545.069 1019.82 548.128 1015.87 551.839C1011.35 556.08 1007.05 560.547 1002.11 564.336C999.861 566.019 999.834 566.895 1001.51 569.359C1005.4 574.632 1009.17 580.012 1013.17 585.18C1018.02 591.47 1017.83 593.984 1012.07 599.392C1008.34 602.891 1004.61 606.39 1001 609.782C998.402 612.221 998.501 612.553 1000.5 615.356C1003.44 619.394 1006.94 622.902 1010.33 626.516C1011.29 627.533 1012.24 628.549 1012.98 629.559C1014.97 632.361 1014.76 635.533 1012.38 637.979C1009.79 640.417 1006.27 640.744 1003.72 638.253C1000.11 634.632 996.72 630.799 993.221 627.072Z" fill="#FD4341"/>
        <path d="M700.247 102.538C700.222 104 701.093 104.934 701.795 106.129C703.596 109.329 703.292 112.642 700.612 114.78C698.015 116.788 694.703 116.485 691.922 113.941C690.096 112.201 688.99 109.637 686.866 108.073C684.233 103.594 680.497 100.245 676.845 96.7664C666.974 87.2194 655.624 79.6671 642.426 75.5319C624.05 69.6983 606.16 71.9368 589.072 80.052C574.419 86.9772 562.125 97.0962 550.618 108.28C549.768 109.021 549.047 109.846 548.067 110.502C545.509 112.166 542.327 111.947 540.241 110.039C537.98 107.833 537.51 104.573 539.411 101.929C540.255 100.629 541.443 99.3688 542.676 98.3224C554.819 86.4437 568.055 76.0124 583.519 68.6905C607.229 57.4865 631.146 56.2056 655.383 66.9517C670.59 73.6833 683.175 83.8845 694.113 96.3402C696.114 98.3781 697.564 100.981 700.247 102.538Z" fill="#1093FF"/>
        <path d="M220.606 771.885C220.75 768.694 220.62 765.717 220.977 762.8C221.596 755.719 223.097 749.092 227.456 743.308C234.207 734.51 243.283 731.721 253.86 733.211C263.646 734.704 271.62 739.726 277.535 747.852C283.207 755.553 283.021 765.549 277.269 773.038C273.788 777.695 269.24 780.989 264.509 784.161C257.686 788.706 250.159 791.339 241.927 792.06C239.133 792.311 238.891 792.676 240.204 795.255C243.075 800.837 246.613 805.81 251 810.295C259.439 819.173 269.755 825.616 280.919 830.781C292.631 836.309 304.822 839.527 317.466 841.863C318.622 842.103 319.594 842.222 320.78 842.613C324.003 843.698 325.803 846.337 325.325 849.437C324.878 852.689 322.515 855.065 319.265 855.408C318.506 855.562 317.837 855.381 317.047 855.383C291.76 851.502 268.374 842.968 248.552 826.281C239.203 818.377 231.734 808.828 226.6 797.54C225.775 795.81 225.223 793.868 224.519 791.956C224.028 790.316 223.144 789.073 221.562 788.287C208.148 782.156 198.276 771.671 189.465 760.181C183.428 752.238 178.21 743.654 173.416 734.826C169.173 727.151 165.627 719.019 161.96 711.069C160.31 707.61 159.236 703.876 157.89 700.356C157.615 699.779 157.492 699.172 157.369 698.565C156.843 695.194 158.445 692.182 161.389 691.111C164.333 690.039 167.739 691.245 169.418 694.066C170.181 695.492 170.793 696.949 171.404 698.405C175.26 708.846 180.148 718.919 185.309 728.779C190.134 737.758 195.352 746.342 201.571 754.406C206.053 760.136 211.08 765.439 216.926 770.102C217.9 771.011 218.723 771.95 220.606 771.885ZM252.001 776.266C256.855 773.701 261.434 770.559 265.525 766.568C268.918 763.035 269.242 759.176 266.223 755.204C262.107 749.716 256.358 747.089 249.612 746.56C243.353 746.091 238.804 749.384 236.359 755.254C233.734 761.793 233.995 768.537 234.591 775.371C234.903 778.469 235.451 778.832 238.641 778.975C243.26 779.145 247.57 777.797 252.001 776.266Z" fill="#FFD500"/>
        </svg>

      <div className="text-center relative z-20 part-1">
        <h3 className="font-semibold header text-white text-2xl sm:text-3xl">Hi {is_shared ? "There!" : store.name}</h3>
        <div className=" text-white text-[100%] mt-2.5 tracking-tight">
          {textRows.map((r, i) => (
            <p key={i} className={`text-row text-row-${i + 1}`}>
              {" "}
              {r}{" "}
            </p>
          ))}
        </div>
      </div>
      <div className="text-center w-full relative z-20 part-2">
        {/* prettier-ignore */}
        <svg className="abs-center w-[55%] arrows" viewBox="0 0 756 1530" fill="none">
        <path d="M755.686 1155.69C755.686 1153.48 753.895 1151.69 751.686 1151.69L715.686 1151.69C713.477 1151.69 711.686 1153.48 711.686 1155.69C711.686 1157.9 713.477 1159.69 715.686 1159.69H747.686V1191.69C747.686 1193.9 749.477 1195.69 751.686 1195.69C753.895 1195.69 755.686 1193.9 755.686 1191.69L755.686 1155.69ZM748.858 1152.86C543.833 1357.88 211.421 1357.88 6.39581 1152.86L0.738956 1158.51C208.888 1366.66 546.365 1366.66 754.515 1158.51L748.858 1152.86Z" fill="white"/>
        <path d="M755.686 374.376C755.686 376.586 753.895 378.376 751.686 378.376L715.686 378.376C713.477 378.376 711.686 376.586 711.686 374.376C711.686 372.167 713.477 370.376 715.686 370.376H747.686V338.376C747.686 336.167 749.477 334.376 751.686 334.376C753.895 334.376 755.686 336.167 755.686 338.376L755.686 374.376ZM748.858 377.205C543.833 172.18 211.421 172.18 6.39581 377.205L0.738956 371.548C208.888 163.399 546.365 163.399 754.515 371.548L748.858 377.205Z" fill="white"/>
        </svg>
        <h3 className="font-semibold text-white text-[200%] leading-tight">
          <span className="carret-text-1 font-medium"></span> <br /> <span className="carret-text-2"></span>
        </h3>
      </div>
    </div>
  );
};

export default IntroCard;
