import { delay } from "../../utils/functions";

export const getCardAnimation1 = function (tl: gsap.core.Timeline) {
  tl.to(".part-1", {
    y: "0",
    delay: 0.2,
    duration: 0.5,
    ease: "power1.out",
    opacity: 1,
  })
    .to(
      ".part-1-text-1",
      {
        y: "0",
        delay: 0.2,
        duration: 0.5,
        ease: "power1.out",
        opacity: 1,
      },
      "<"
    )
    .to(
      ".card-image",
      {
        y: "0",
        delay: 0.6,
        scale: 1,
        duration: 2,
        ease: "expo.out",
        opacity: 1,
      },
      "<"
    )
    .to(
      ".part-1-text-2",
      {
        y: "0",
        delay: 0.5,
        duration: 1,
        ease: "power1.out",
        opacity: 1,
      },
      "<"
    )
    .to(
      ".part-1-text-3",
      {
        y: "0",
        delay: 0.5,
        duration: 1,
        ease: "power1.out",
        opacity: 1,
      },
      "<"
    );
};

export const getCardAnimation2 = function (tl: gsap.core.Timeline) {
  for (let i = 0; i < 5; i++) {
    tl.to(
      `.data-card-${i + 1}`,
      {
        y: "0",
        delay: 0.2,
        duration: 0.5,
        ease: "power2.out",
        opacity: 1,
      },
      "<"
    );
  }

  tl.to(
    `.trinkets`,
    {
      duration: 0.5,
      delay: 0.8,
      ease: "power1.out",
      opacity: 1,
    },
    "<"
  );
};
