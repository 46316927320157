export const getCardAnimation1 = function (tl: gsap.core.Timeline) {
  tl.to(".text-card", {
    y: "0",
    delay: 2,
    duration: 0.5,
    ease: "power1.out",
    opacity: 1,
  });

  tl.to(".card-illustration",{
    opacity: 1,
  },"<")

  tl.to(".part-1", { display: "flex", opacity: 1, duration: 0 })
    .to(".text-1", {
      y: "0",
      delay: 0.2,
      duration: 0.5,
      ease: "power1.out",
      opacity: 1,
    })
    .to(
      ".stat",
      {
        scale: 1,
        delay: 0.5,
        duration: 0.8,
        ease: "bounce.out",
        opacity: 1,
      },
      "<"
    );
  tl.to(
    `.trinkets`,
    {
      duration: 1.5,
      delay: 0.5,
      ease: "power1.out",
      opacity: 1,
    },
    "<"
  ).to(".text-2", {
    y: "0",
    delay: 0.2,
    duration: 0.5,
    ease: "power1.out",
    opacity: 1,
  },"<");
};
