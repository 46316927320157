import Numbers from "@/assets/icons/wrapped/numbers.svg";
import Confetti from "@/assets/icons/wrapped/trinkets-2.svg";
import { YearWrappedResponse } from "@/assets/interfaces";
import { getCardAnimation1, getCardAnimation2 } from "@/assets/js/animations/wrapped/store-referrals";
import { useListener } from "@/components/hooks/useListener";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, { useEffect, useRef } from "react";

gsap.registerPlugin(useGSAP);

interface Props extends YearWrappedResponse {}
const TotalReferralsCard: React.FC<Props> = ({ no_of_referrals, catlog_credits_earned }) => {
  const container = useRef();
  let referralCountType = no_of_referrals == 0 ? 2 : no_of_referrals < 20 ? 1 : 0;
  let referralEarningsType = catlog_credits_earned == 0 ? 0 : 1;

  useEffect(() => {
    if (videoRef) {
      videoRef.current.play();
    }
  }, []);

  const tlRef = useRef<gsap.core.Timeline>();
  const videoRef = useRef<HTMLVideoElement>(null);

  useListener("toggle-play-gsap", () => {
    if (tlRef.current) {
      if (tlRef.current.paused()) {
        tlRef.current.play();
        videoRef.current.play();
      } else {
        tlRef.current.pause();
        videoRef.current.pause();
      }
    }
  });

  useGSAP(
    () => {
      const tl = gsap.timeline();
      tlRef.current = tl;

      tl.set(".row", { y: "40", opacity: 0 });
      tl.set(".text-card", { y: "50", opacity: 0 });
      tl.set(".text-pop", { opacity: 0, scale: 0, y: "20" });
      tl.set(".part-2-footer", { opacity: 0, y: "10" });
      tl.set(".trinkets", { opacity: 0 });
      tl.set(".part-2", { display: "none" });
      tl.set(".card-illustration", { opacity: 0 });

      getCardAnimation1(tl, referralCountType);
      getCardAnimation2(tl, referralEarningsType);
    },
    { scope: container, dependencies: [referralCountType] }
  );

  const part1Content = {
    0: [
      <span key={0}>Rumour has it that you</span>,
      <span key={1}>
        are an influencer!
        <br />
        <br />
      </span>,
      <span key={2}>
        {" "}
        <span className="text-[#FFD500]"> {no_of_referrals} people</span> started using{" "}
      </span>,
      <span key={3}>Catlog because of you.</span>,
    ],
    1: [
      <span key={0}>You referred</span>,
      <span key={1} className="text-[#FFD500]">
        {" "}
        {no_of_referrals} People
      </span>,
      <span key={2}>to Catlog in</span>,
      <span key={3}>2024.</span>,
    ],
    2: [<span key={0}>You didn’t refer</span>, <span key={1}>anyone to Catlog</span>, <span key={2}>in 2024 😔</span>],
  };

  const part2Content = {
    1: [
      <p key={0}>You can earn way more, we promise!</p>,
      <p key={1}>
        Share your referral link and watch <br /> the funds roll in.
      </p>,
    ],

    0: [
      <p key={0}>
        <span className="opacity-50">Did you know </span>that every time you refer a
      </p>,
      <p key={1}>
        vendor, you earn <span className="text-[#FFD500]">Catlog credits</span>? You can use
      </p>,
      <p key={2}>them to pay your subscription fees.</p>,
    ],
  };

  return (
    <div ref={container} className="relative flex flex-col items-center justify-start w-full h-full bg-primary-400">
      <div className="w-full absolute left-0 top-0 h-full">
        <div className="w-full h-full absolute z-[2]"></div>
        <video
          ref={videoRef}
          autoPlay
          controls={false}
          playsInline={true}
          style={{ pointerEvents: "none" }}
          className="w-full h-full object-cover"
          src="https://catlog-s3.s3.eu-west-2.amazonaws.com/year+wrapped/videos/total_referrals.mp4"
        />
      </div>
      <div className="text-center absolute left-0 right-0 mx-auto w-full h-[63%] p-5  leading-tight ">
        <div className="w-full h-full relative text-2xl sm:text-3xl overflow-hidden text-card rounded-[80px]">
          <Confetti className="w-[90%] abs-center trinkets" />

          <div className="part-1 w-full h-full flex   flex-col items-center justify-center">
            {part1Content[referralCountType].map((r, i) => (
              <h3 key={i} className={`text-white row row-${i + 1}`}>
                {r}
              </h3>
            ))}
          </div>

          <div className="part-2 w-full h-full flex flex-col items-center justify-center">
            {referralEarningsType !== 0 && (
              <div className="text-[280%]">
                <div className="w-full  font-semibold relative justify-center -space-x-3.75">
                  <h1 className="text-pop text-pop-1 inline-block   text-[#BDB4EC] ">w</h1>
                  <h1 className="text-pop text-pop-2 inline-block z-0 relative top-5  outline-text  text-[#7563D4]">
                    o
                  </h1>
                  <h1 className="text-pop text-pop-3 inline-block relative z-10  text-[#BDB4EC]">a</h1>
                  <h1 className="text-pop text-pop-4 inline-block pl-2.5 relative bottom-2 outline-text  text-[#7563D4]">
                    h
                  </h1>
                  <h1 className="text-pop text-pop-5 inline-block pl-2.5 text-[#BDB4EC]  right-20">h</h1>
                </div>
                <div className="relative float-right w-[fit-content]  font-semibold -mt-15  -space-x-3.75">
                  <h1 className="text-pop text-pop-6 inline-block pl-2.5 text-[#BDB4EC] relative left-10">h</h1>
                  <h1 className="text-pop text-pop-7 inline-block pl-2.5 text-[#BDB4EC] relative left-10">h</h1>
                  <h1 className="text-pop text-pop-8 inline-block pl-2.5 relative bottom-5 left-5 outline-text  text-[#7563D4]">
                    h
                  </h1>
                </div>
              </div>
            )}

            {referralEarningsType == 0 && <h5 className="text-white text-1sm sm:text-sm w-full">YOU EARNED</h5>}

            {referralEarningsType == 0 && (
              <div className="h-[50%] w-full ">
                <Numbers className="absolute w-[300%] numbers-dial top-[7vh] left-1/2 -translate-x-1/2" />
              </div>
            )}

            {referralEarningsType !== 0 && (
              <>
                <h3 className="text-white text-2xl sm:text-3xl ">
                  <p className="row part-2-text-1 ">
                    You also earned <span className="text-[#FFD500]"> {catlog_credits_earned} </span>{" "}
                  </p>
                  <p className="row part-2-text-2">in Catlog credits.</p>
                </h3>
              </>
            )}

            <h2 className="text-white part-2-footer">Credits</h2>
            <p className="text-1xs leading-tight z-20 text-white part-2-footer mt-2.5">
              {part2Content[referralEarningsType]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TotalReferralsCard;
