export const getCardAnimation1 = function (tl: gsap.core.Timeline) {
  tl.to(".text-card", {
    y: "0",
    delay: 2,
    duration: 0.5,
    ease: "power1.out",
    opacity: 1,
  });

  tl.to(".card-illustration",{
    opacity: 1,
  },"<")

  tl.to(".part-1-text-1", {
    y: "0",
    delay: 0.2,
    duration: 0.5,
    ease: "power1.out",
    opacity: 1,
  })
    .to(
      ".part-1-stat",
      {
        scale: 1,
        delay: 0.5,
        duration: 0.8,
        ease: "bounce.out",
        opacity: 1,
      },
      "<"
    )
    .to(".part-1-text-2", {
      y: "0",
      delay: 0.2,
      duration: 0.5,
      ease: "power1.out",
      opacity: 1,
    })
    .to(".part-1-text-3", {
      y: "0",
      delay: 0.2,
      duration: 0.5,
      ease: "power1.out",
      opacity: 1,
    })
    .to(".part-1", { opacity: 0, ease: "power1.in", delay: 2 })
    .to(".part-1", { display: "none", duration: 0 });
};

export const getCardAnimation2 = function (tl: gsap.core.Timeline, products: number) {
  tl.to(".part-2", { display: "flex", opacity: 1, duration: 0 }).to(".part-2-text-1", {
    y: "0",
    delay: 0.2,
    duration: 0.5,
    ease: "power1.out",
    opacity: 1,
  });
  for (let i = 0; i < products; i++) {
    tl.to(
      `.product-pill-${i}`,
      {
        y: "0",
        delay: i == 0 ? 0.7 : 0.1,
        duration: 0.5,
        ease: "power1.out",
        opacity: 1,
      },
      "<"
    );
  }
  tl.to(".part-2-text-1", {
    y: "20",
    delay: 5,
    duration: 0.5,
    ease: "power1.out",
    opacity: 0,
  });
  for (let i = 0; i < products; i++) {
    tl.to(
      `.product-pill-${i}`,
      {
        y: "20",
        delay:  0.1,
        duration: 0.5,
        ease: "power1.out",
        opacity: 0,
      },
      "<"
    );
  }
};
