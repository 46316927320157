export const getCardAnimation1 = function (tl: gsap.core.Timeline) {
    tl.to(".part-2", { display: "none", opacity: 0, duration: 0 });
    tl.to(".text-card", {
      y: "0",
      delay: 2,
      duration: 0.5,
      ease: "power1.out",
      opacity: 1,
    })
    
    tl.to(".card-illustration",{
      opacity: 1,
    },"<")
  
    for (let i = 0; i < 4; i++) {
      tl.to(
        `.row-${i + 1}`,
        {
          y: "0",
          delay: 0.2,
          duration: 0.5,
          ease: "power1.out",
          opacity: 1,
        },
        "<"
      );
    }
  
    tl.to(
      `.trinkets`,
      {
        duration: 0.5,
        delay: 0.8,
        ease: "power1.out",
        opacity: 1,
      },
      "<"
    )
    tl.to(".part-1-text-1",{
        duration: 1,
        ease: "bounce.out",
        opacity: 1,
        scale: 1,
    })
    for (let i = 0; i < 4; i++) {
      tl.to(
        `.row-${i + 1}`,
        {
          y: "20",
          delay: i == 0 ? 2 : 0.1,
          duration: 0.5,
          ease: "power1.out",
          opacity: 0,
        },
        "<"
      )
    }
    tl.to(".part-1-text-1",{
        duration: 0.5,
        delay: 0.2,
        ease: "power1.in",
        opacity: 0,
        scale: 0,
    },"<")
    tl.to(".part-1", { display: "none" });
  };
  
  export const getCardAnimation2 = function (tl: gsap.core.Timeline) {
    tl.to(".part-2", { display: "flex", opacity: 1, duration: 0 })
      .to(".part-2-text-1", {
        y: "0",
        delay: 0.2,
        duration: 0.5,
        ease: "power1.out",
        opacity: 1,
      })
      .to(
        ".map-illustration",
        {
          scale: 1,
          delay: 0.5,
          duration: 0.5,
          ease: "power3.out",
          opacity: 1,
        },
        "<"
      )
      .to(".part-2-text-2", {
          y: "0",
          delay: 0.8,
          duration: 0.5,
          ease: "power1.out",
          opacity: 1,
        },"<")
      .to(".part-2-text-3", {
          y: "0",
          delay: 0.4,
          duration: 0.5,
          ease: "power1.out",
          opacity: 1,
        },"<")
      .to(".part-2-text-4", {
          y: "0",
          delay: 0.2,
          duration: 0.5,
          ease: "power1.out",
          opacity: 1,
        })
  };
  