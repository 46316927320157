import Illus from "@/assets/icons/wrapped/trinkets-4.svg";
import { YearWrappedResponse } from "@/assets/interfaces";
import { getCardAnimation1, getCardAnimation2 } from "@/assets/js/animations/wrapped/store-products";
import { useListener } from "@/components/hooks/useListener";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, { useEffect, useRef } from "react";

gsap.registerPlugin(useGSAP);

interface Props extends YearWrappedResponse {}
const StoreTopProductsCard: React.FC<Props> = ({ top_product, other_top_products, is_shared }) => {
  const container = useRef();
  const tlRef = useRef<gsap.core.Timeline>();
  const videoRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    if (videoRef) {
      videoRef.current.play();
    }
  }, []);

  useListener("toggle-play-gsap", () => {
    if (tlRef.current) {
      if (tlRef.current.paused()) {
        tlRef.current.play();
        videoRef.current.play();
      } else {
        tlRef.current.pause();
        videoRef.current.pause();
      }
    }
  });

  useGSAP(
    () => {
      const tl = gsap.timeline();
      tlRef.current = tl;
      tl.set(".row", { y: "20", opacity: 0 }).set(".text-card", { y: "50", opacity: 0 });
      tl.set(".trinkets", { opacity: 0 });
      tl.set(".card-illustration", { opacity: 0 });

      tl.set(".part-1-text-1", { y: "-20", opacity: 0 });
      tl.set(".part-1-stat", { scale: 0, opacity: 0 });
      tl.set(".part-1-text-2", { y: "20", opacity: 0 });
      tl.set(".part-1-text-3", { y: "20", opacity: 0 });
      tl.set(".part-2-text-1", { y: "20", opacity: 0 });

      tl.set(".part-2", { display: "none" });
      tl.set(".product-pill", { y: "20", opacity: 0 });

      getCardAnimation1(tl);
      getCardAnimation2(tl, 5);
    },
    { scope: container }
  );

  return (
    <div ref={container} className="relative flex flex-col items-center justify-start w-full h-full bg-accent-red-500">
      <div className="w-full absolute left-0 top-0 h-full">
        <div className="w-full h-full absolute z-[2]"></div>
        <video
          ref={videoRef}
          autoPlay
          controls={false}
          playsInline={true}
          style={{ pointerEvents: "none" }}
          className="w-full h-full object-cover"
          src="https://catlog-s3.s3.eu-west-2.amazonaws.com/year+wrapped/videos/top_products.mp4"
        />
      </div>
      <div className="text-center text-white absolute left-0 right-0 mx-auto w-full h-[65%] p-10 leading-tight ">
        <div className="w-full h-full overflow-hidden text-2xl sm:text-3xl text-card rounded-[80px]">
          <Illus className="w-[100%]  abs-center trinkets" />

          <div className="part-1 w-full h-full flex flex-col items-center justify-center">
            <div className="flex items-center flex-col gap-2.5 ">
              <h3 className="text-xl text-white font-semibold tracking-tight part-1-text-1 ">
                {is_shared ? "My" : "Your"} customers really love
              </h3>
              <div className="bg-accent-red-300 px-3 pt-2 pb-3 rounded-xl part-1-stat">
                <h3 className="text-white font-bold responsive-wrapped-heading-two">{top_product.name}</h3>
              </div>
              <h3 className="text-xl text-white font-semibold tracking-tight part-1-text-2">
                They ordered it <span className="opacity-60"> {top_product.orders_count} </span> <br /> times in 2024.
              </h3>
            </div>
          </div>
          <div className="part-2 w-full h-full flex flex-col items-center justify-start p-6.25 pt-10">
            <h4 className="text-sm font-semibold part-2-text-1 ">OTHER TOP PRODUCTS</h4>
            <div className="w-full flex flex-col gap-2.5 mt-7.5">
              {other_top_products.map((p, i) => (
                <div
                  key={i}
                  className={`product-pill product-pill-${i} w-full bg-white p-1.25 rounded-lg flex items-center justify-between bg-opacity-10 `}
                >
                  <div className="flex items-center gap-2.5">
                    <div className="w-6.25 h-6.25 text-base bg-accent-red-500 flex items-center justify-center rounded-full">
                      {i + 1}
                    </div>
                    <span className="text-1sm font-display font-semibold">{p.name}</span>
                  </div>
                  <span className="text-sm opacity-50 font-display"></span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StoreTopProductsCard;
