import Illus from "@/assets/icons/wrapped/trinkets-3.svg";
import { YearWrappedResponse } from "@/assets/interfaces";
import { getCardAnimation1 } from "@/assets/js/animations/wrapped/store-month";
import { useListener } from "@/components/hooks/useListener";
import { useGSAP } from "@gsap/react";
import dayjs from "dayjs";
import gsap from "gsap";
import React, { useEffect, useRef } from "react";

gsap.registerPlugin(useGSAP);

interface Props extends YearWrappedResponse {}
const StoreBestMonthCard: React.FC<Props> = ({ month_with_highest_orders, is_shared }) => {
  const container = useRef();
  const tlRef = useRef<gsap.core.Timeline>();
  const videoRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    if (videoRef) {
      videoRef.current.play();
    }
  }, []);

  useListener("toggle-play-gsap", () => {
    if (tlRef.current) {
      if (tlRef.current.paused()) {
        tlRef.current.play();
        videoRef.current.play();
      } else {
        tlRef.current.pause();
        videoRef.current.pause();
      }
    }
  });

  useGSAP(
    () => {
      const tl = gsap.timeline();
      tlRef.current = tl;
      tl.set(".row", { y: "20", opacity: 0 }).set(".text-card", { y: "50", opacity: 0 });
      tl.set(".trinkets", { opacity: 0 });
      tl.set(".card-illustration", { opacity: 0 });

      tl.set(".part-1", { display: "none" });
      tl.set(".text-1", { y: "-20", opacity: 0 });
      tl.set(".stat", { scale: 0, opacity: 0 });
      tl.set(".text-2", { y: "20", opacity: 0 });

      getCardAnimation1(tl);
    },
    { scope: container }
  );

  const month = dayjs().set("month", month_with_highest_orders).format("MMMM");

  return (
    <div ref={container} className="relative flex flex-col items-center justify-start w-full h-full bg-[#38D771]">
      <div className="w-full absolute left-0 top-0 h-full">
        <div className="w-full h-full absolute z-[2]"></div>
        <video
          ref={videoRef}
          autoPlay
          controls={false}
          playsInline={true}
          style={{ pointerEvents: "none" }}
          className="w-full h-full object-cover"
          src="https://catlog-s3.s3.eu-west-2.amazonaws.com/year+wrapped/videos/order_best_month.mp4"
        />
      </div>
      <div className="text-center absolute left-0 right-0 mx-auto w-full h-[65%] p-10  leading-tight ">
        <div className="w-full h-full text-2xl sm:text-3xl text-card rounded-[80px]">
          <Illus className="w-[80%] abs-center trinkets" />
          <div className="part-1 w-full h-full flex flex-col items-center justify-center">
            <div className="flex items-center flex-col gap-5 ">
              <h3 className="text-xl text-white font-semibold tracking-tight text-1 ">
                {is_shared ? "There was so much love in the air in" : "What was in the air in"}
              </h3>
              <div className="bg-accent-green-100 p-2.5 px-5 rounded-xl stat">
                <h3 className="text-[#38D771] font-bold ">{month}</h3>
              </div>
              {is_shared ? (
                <h3 className="text-xl text-white font-semibold tracking-tight text-2">
                  I had my highest order <br /> volumes in that month.
                </h3>
              ) : (
                <h3 className="text-xl text-white font-semibold tracking-tight text-2">
                  You had your highest order <br /> volumes in this month.
                </h3>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StoreBestMonthCard;
