import Map from "@/assets/icons/wrapped/map.svg";
import Confetti from "@/assets/icons/wrapped/trinkets-5.svg";
import { YearWrappedResponse } from "@/assets/interfaces";
import { getCardAnimation1, getCardAnimation2 } from "@/assets/js/animations/wrapped/top-location";
import { useListener } from "@/components/hooks/useListener";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, { useEffect, useRef } from "react";

gsap.registerPlugin(useGSAP);

interface Props extends YearWrappedResponse {}
const TopOrderLocationCard: React.FC<Props> = ({
  top_orders_location,
  no_of_orders_for_location,
  no_of_customers,
  is_shared,
}) => {
  const container = useRef();
  const tlRef = useRef<gsap.core.Timeline>();
  const videoRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    if (videoRef) {
      videoRef.current.play();
    }
  }, []);

  useListener("toggle-play-gsap", () => {
    if (tlRef.current) {
      if (tlRef.current.paused()) {
        tlRef.current.play();
        videoRef.current.play();
      } else {
        tlRef.current.pause();
        videoRef.current.pause();
      }
    }
  });
  useGSAP(
    () => {
      const tl = gsap.timeline();
      tlRef.current = tl;
      tl.set(".row", { y: "20", opacity: 0 });
      tl.set(".text-card", { y: "50", opacity: 0 });
      tl.set(".trinkets", { opacity: 0 });
      tl.set(".map-illustration", { opacity: 0, scale: 0 });
      tl.set(".part-1-text-1", { scale: 4, opacity: 0 });
      tl.set(".card-illustration", { opacity: 0 });

      tl.set(".part-2", { display: "none" });
      tl.set(".part-2-text-1", { y: "-20", opacity: 0 });
      tl.set(".part-2-stat", { scale: 0, opacity: 0 });
      tl.set(".part-2-text-2", { y: "20", opacity: 0 });
      tl.set(".part-2-text-3", { y: "-20", opacity: 0 });
      tl.set(".part-2-text-4", { y: "20", opacity: 0 });

      getCardAnimation1(tl);
      getCardAnimation2(tl);
    },
    { scope: container }
  );

  const textRows1 = [
    <span key={0}>Of all the places in the</span>,
    <span key={1}>galaxy, {is_shared ? "my" : "your"} biggest </span>,
    <span key={2}>fans are in</span>,
  ];

  return (
    <div ref={container} className="relative flex flex-col items-center justify-start w-full h-full bg-[#FFCE38]">
      <div className="w-full absolute left-0 top-0 h-full">
        <div className="w-full h-full absolute z-[2]"></div>
        <video
          ref={videoRef}
          autoPlay
          controls={false}
          playsInline={true}
          style={{ pointerEvents: "none" }}
          className="w-full h-full object-cover"
          src="https://catlog-s3.s3.eu-west-2.amazonaws.com/year+wrapped/videos/top_order_location.mp4"
        />
      </div>
      <div className="text-center absolute left-0 right-0 mx-auto w-full h-[65%] p-5 pt-10  leading-tight ">
        <div className="w-full h-full text-2xl sm:text-3xl text-card rounded-[80px]">
          <Confetti className="w-[90%] abs-center trinkets z-20" />

          <div className="part-1 w-full h-full flex flex-col items-center justify-center">
            {textRows1.map((r, i) => (
              <h3 key={i} className={`text-black text-lg font-semibold row row-${i + 1}`}>
                {r}
              </h3>
            ))}
            <h1 className="font-semibold part-1-text-1 text-[#624FC3] responsive-wrapped-heading-text mt-5">
              {" "}
              {top_orders_location}{" "}
            </h1>
          </div>

          <div className="part-2 w-full h-full flex flex-col items-center justify-start py-7.5">
            <div className="flex items-center flex-col gap-2.5 relative">
              <h4 className="text-sm font-semibold part-2-text-1 tracking-widest mt-[2vh]">TOP ORDER LOCATION</h4>
              <Map className="w-[100%] mt-5 map-illustration" />

              <h3 className="text-white responsive-wrapped-heading-text absolute top-[35%] part-2-text-2 leading-tight font-semibold capitalize">
                {top_orders_location}{" "}
              </h3>
              <h3 className="text-[#624FC3] responsive-wrapped-sub-heading-text  part-2-text-3 absolute top-[65%] font-semibold w-[50%]">
                <span className="absolute text-sm text-black -top-3 left-2.5 tracking-normal">
                  {is_shared ? "I" : "You"} got
                </span>
                <p> {no_of_orders_for_location} </p>
                <span className="absolute text-sm text-black -bottom-5 right-2.5 tracking-tight">Orders in 2024</span>
              </h3>
            </div>
            <h3 className="mt-2.5 z-30 bg-[#ffd441] px-5 py-2.5 pb-3.75 w-full rounded-15 border-white border border-opacity-20 text-sm font-semibold part-2-text-4 tracking-normal">
              But the love didn’t come from <span className="text-[#624FC3]"> {top_orders_location} </span> alone; it
              was all over.
              <br />
              {is_shared ? "I" : "You"} had <span className="text-[#624FC3]"> {no_of_customers} </span> customers in
              2024.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TopOrderLocationCard;
