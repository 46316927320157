import Illus from "@/assets/icons/wrapped/trinkets-3.svg";
import { YearWrappedResponse } from "@/assets/interfaces";
import { getCardAnimation1, getCardAnimation2 } from "@/assets/js/animations/wrapped/total-deliveries";
import { useListener } from "@/components/hooks/useListener";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, { useEffect, useRef } from "react";

gsap.registerPlugin(useGSAP);

interface Props extends YearWrappedResponse {}
const TotalDeliveriesCard: React.FC<Props> = ({ no_of_fulfilled_deliveries }) => {
  const container = useRef();
  const tlRef = useRef<gsap.core.Timeline>();
  const videoRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    if (videoRef) {
      videoRef.current.play();
    }
  }, []);

  useListener("toggle-play-gsap", () => {
    if (tlRef.current) {
      if (tlRef.current.paused()) tlRef.current.play();
      else tlRef.current.pause();
    }
  });

  useGSAP(
    () => {
      const tl = gsap.timeline();
      tlRef.current = tl;

      tl.set(".row", { y: "20", opacity: 0 });
      tl.set(".text-card", { y: "50", opacity: 0 });
      tl.set(".trinkets", { opacity: 0 });
      tl.set(".map-illustration", { opacity: 0, scale: 0 });
      tl.set(".part-1-text-1", { scale: 4, opacity: 0 });
      tl.set(".card-illustration", { opacity: 0 });

      tl.set(".part-2", { display: "none" });
      tl.set(".part-2-stat", { scale: 0, opacity: 0 });
      tl.set(".part-2-text-1", { y: "-20", opacity: 0 });
      tl.set(".part-2-text-2", { y: "20", opacity: 0 });
      tl.set(".part-2-text-3", { y: "20", opacity: 0 });

      getCardAnimation1(tl);
      getCardAnimation2(tl);
    },
    { scope: container }
  );

  const showAlt = no_of_fulfilled_deliveries > 10;

  const textRows1 = showAlt
    ? [<span key={0}>Can we say you </span>, <span key={1}>delivered? because</span>, <span key={2}>you DID!!!</span>]
    : [<span key={0}>You’re missing out </span>, <span key={1}>on smooth deliveries.</span>];

  return (
    <div ref={container} className="relative flex flex-col items-center justify-start w-full h-full bg-[#38D771]">
      <div className="w-full absolute left-0 top-0 h-full">
        <div className="w-full h-full absolute z-[2]"></div>
        <video
          ref={videoRef}
          autoPlay
          controls={false}
          playsInline={true}
          style={{ pointerEvents: "none" }}
          className="w-full h-full object-cover"
          src="https://catlog-s3.s3.eu-west-2.amazonaws.com/year+wrapped/videos/total_deliveries.mp4"
        />
      </div>

      <div className="text-center absolute left-0 right-0 mx-auto w-full h-[65%] p-10  leading-tight ">
        <div className="w-full h-full text-2xl sm:text-3xl overflow-hidden text-card rounded-[80px] ">
          <Illus className="w-[100%] abs-center trinkets" />

          <div className="part-1 w-full h-full flex flex-col items-center justify-center">
            {textRows1.map((r, i) => (
              <h3 key={i} className={`text-white font-semibold row row-${i + 1}`}>
                {r}
              </h3>
            ))}
          </div>

          <div className="part-2 w-full h-full flex flex-col items-center justify-center">
            <div className="flex items-center flex-col gap-3.75 ">
              <h3 className="text-xl text-white font-semibold tracking-tight part-2-text-1 ">You made</h3>
              <div className="bg-accent-green-100 p-2.5 px-5 rounded-xl part-2-stat">
                <h3 className="text-[#38D771] font-bold "> {no_of_fulfilled_deliveries} </h3>
              </div>
              <h3 className="text-xl text-white font-semibold tracking-tight part-2-text-2">
                deliveries with <br /> Catlog in 2024
              </h3>
              {!showAlt && (
                <p className="text-1xs p-2.5 pt-0 bg-[#38D771] bg-opacity-20  leading-tight font-display text-white part-2-text-3">
                  With over 20+ delivery partners on your <br /> dashboard, Catlog makes deliveries <br /> much easier.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TotalDeliveriesCard;
