import Logo from "@/assets/icons/logo.svg";
import { YearWrappedResponse } from "@/assets/interfaces";
import { getCardAnimation2 } from "@/assets/js/animations/wrapped/year-review";
import { useGSAP } from "@gsap/react";
import dayjs from "dayjs";
import gsap from "gsap";
import React, { useRef } from "react";
import Bg from "./big-moves-bg";
import { millify, toCurrency } from "@/assets/js/utils/functions";
import { toNaira } from "@/assets/js/utils/utils";

gsap.registerPlugin(useGSAP);

interface Props extends YearWrappedResponse {}
const YearReviewCard: React.FC<Props> = ({
  no_of_store_visits,
  total_order_count,
  top_orders_location,
  top_product,
  month_with_highest_orders,
  store_currency,
  total_payments_volume,
  is_shared,
}) => {
  const container = useRef();

  useGSAP(
    () => {
      const tl = gsap.timeline();
      tl.set(".card-image", { y: "20", scale: 0, opacity: 0 });
      tl.set(".data-card", { y: "40", opacity: 0 });

      getCardAnimation2(tl);
    },
    { scope: container }
  );

  const month = dayjs().set("month", month_with_highest_orders).format("MMMM");

  return (
    <div
      ref={container}
      className="relative flex flex-col items-start  px-5 justify-start pt-10 w-full h-full bg-[#5644B3]"
    >
      <div className="w-full absolute left-0 top-0 h-full">{Bg("#6955D1", "#5644B3")}</div>
      <div className="text-center part-2 absolute top-0 left-0 right-0 mx-auto w-full h-[80%] py-10 px-5 leading-tight ">
        <div className="w-full h-full text-2xl sm:text-3xl  overflow-hidden text-card rounded-[10px] bg-white ">
          <div className="font-display font-semibold w-full h-full flex flex-col p-5 items-center justify-between">
            <h2 className="py-5 text-[100%] text-[#5644B3]">{is_shared ? "My" : "Your"} Year In Review</h2>
            <div className="w-full h-full flex flex-col items-center justify-end">
              <div className="data-card data-card-1 h-[20%] flex items-center px-[5%] justify-between bg-[#5644B3] w-full rounded-t-8">
                <span className="text-xs sm:text-1xs text-white">TOTAL STORE VISITS</span>
                <h3 className="text-[#F2B700]"> {no_of_store_visits.toLocaleString()} </h3>
              </div>

              <div className="data-card data-card-2 h-[20%] flex items-center px-[5%] justify-between bg-[#E5DFFF] w-full">
                <h3 className="text-[#5644B3]"> {total_order_count.toLocaleString()} </h3>
                <span className="text-xs sm:text-1xs text-black-secondary uppercase">TOTAL ORDERS</span>
              </div>

              <div className="data-card data-card-3 h-[20%] flex items-center px-[5%] justify-between bg-[#38D771] w-full">
                <span className="text-xs sm:text-1xs text-white uppercase text-left">PAYMENTS</span>
                <h3 className="text-white text-right">
                  {" "}
                  {store_currency} {millify(toNaira(total_payments_volume))}{" "}
                </h3>
              </div>

              <div className="data-card data-card-4 h-[20%] flex items-center px-[5%] justify-between bg-[#FFF1F4] w-full">
                <h3 className="text-[#DB577A] text-left leading-[1.8rem]">{top_product.name}</h3>
                <span className="text-xs sm:text-1xs text-black-secondary text-right  uppercase">TOP PRODUCT</span>
              </div>

              <div className="data-card data-card-5 h-[20%] flex items-center px-[5%] justify-between bg-[#F2B700] w-full rounded-b-8">
                <span className="text-xs sm:text-1xs text-white  uppercase text-left">BEST MONTH</span>
                <h3 className="text-white text-right leading-[1.8rem]">{month}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex part-2 left-0 px-5 items-center absolute justify-between w-full bottom-10">
        <Logo className="w-30 sm:w-32" />
        <h3 className="font-bold text-white text-right sm:text-sm text-xs">CATLOG CHRONICLES `24</h3>
      </div>
    </div>
  );
};
export default YearReviewCard;
