export const getCardAnimation1 = function (tl: gsap.core.Timeline, vidRef: HTMLVideoElement) {
  tl.to(".part-2", { display: "none", opacity: 0, duration: 0 });
  tl.to(".text-card", {
    y: "0",
    delay: 1.5,
    duration: 0.5,
    ease: "power1.out",
    opacity: 1,
  });

  tl.to(
    ".card-illustration",
    {
      opacity: 1,
    },
    "<"
  );

  for (let i = 0; i < 3; i++) {
    tl.to(
      `.row-${i + 1}`,
      {
        y: "0",
        delay: 0.2,
        duration: 0.5,
        ease: "power1.out",
        opacity: 1,
      },
      "<"
    );
  }

  tl.to(
    `.trinkets`,
    {
      duration: 0.5,
      delay: 0.8,
      ease: "power1.out",
      opacity: 1,
    },
    "<"
  );
  for (let i = 0; i < 3; i++) {
    tl.to(
      `.row-${i + 1}`,
      {
        y: "20",
        delay: i == 0 ? 2 : 0.1,
        duration: 0.5,
        ease: "power1.out",
        opacity: 0,
      },
      "<"
    );
  }
  tl.to(".part-1", { display: "none" });
};

export const getCardAnimation2 = function (tl: gsap.core.Timeline, isShared: boolean) {
  tl.to(".part-2", { display: "flex", opacity: 1, duration: 0 });
  for (let i = 0; i < 3; i++) {
    tl.to(
      `.row-part-2-${i + 1}`,
      {
        y: "0",
        delay: 0.2,
        duration: 0.5,
        ease: "power1.out",
        opacity: 1,
      },
      "<"
    );
  }
  tl.to(
    ".carret-text-1",
    { text: isShared ? "To help me get more visiblity" : "to increase your store visits, ", duration: 2, delay: 1.5 },
    "<"
  ).to(".carret-text-2", {
    text: isShared ? "share my store link with friends this year! 🫶" : "share your store link more this year!",
    duration: 1,
  });
};
