const Bg = (fill1: string, fill2: string) => {
    //prettier-ignore
    return<svg className="w-full h-full object-cover" viewBox="0 0 1080 1920" fill="none" >
    <g clipPath="url(#clip0_5633_1756)">
    <rect width="1080" height="1920" fill={fill1}/>
    <path d="M-1192.62 1358.67C-800.078 1241.97 -559.753 895.024 -292.119 611.179C-215.208 529.593 -133.938 451.957 -45.136 383.366C-51.6874 388.419 -58.3255 393.522 -64.8769 398.574C21.1342 332.75 113.725 276.521 214.009 235.019C206.358 238.166 198.62 241.364 190.968 244.511C428.389 147.735 689.655 122.062 917.46 -0.569151C1111.49 -105.09 1272.83 -269.251 1379.38 -461.708C1443.48 -577.471 1489.18 -704.315 1514.59 -834.108C1523.92 -881.754 1500.11 -936.596 1448.84 -947.99C1402.43 -958.376 1344.89 -933.238 1334.96 -882.24C1324.33 -827.84 1310.35 -774.269 1292.62 -721.772C1288.54 -709.834 1284.33 -697.933 1279.89 -686.018C1277.43 -679.284 1274.83 -672.587 1272.09 -665.927C1271.12 -663.404 1270.11 -660.969 1269.14 -658.447C1264.98 -647.848 1277.46 -678.032 1271.05 -663.131C1260.5 -638.798 1249.55 -614.575 1237.57 -590.917C1214.06 -544.435 1187.41 -499.602 1157.58 -456.863C1150.78 -447.046 1143.7 -437.303 1136.58 -427.646C1127.59 -415.413 1141.31 -433.844 1141.89 -434.64C1140.04 -431.841 1137.65 -429.188 1135.62 -426.513C1131.41 -421.197 1127.2 -415.882 1122.91 -410.517C1106.94 -390.786 1090.25 -371.685 1072.91 -353.126C1033.96 -311.587 991.771 -273.258 946.69 -238.34C953.242 -243.392 959.88 -248.495 966.431 -253.548C892.301 -196.546 811.177 -150.055 724.747 -114.358C732.398 -117.505 740.136 -120.702 747.788 -123.85C623.853 -73.3113 493.365 -43.1229 364.624 -7.70796C229.145 29.6347 95.3906 75.5655 -25.8556 147.761C-269.734 292.952 -452.399 510.766 -638.948 719.853C-739.513 832.07 -843.297 942.911 -963.064 1035.27C-956.512 1030.22 -949.874 1025.11 -943.323 1020.06C-1016.96 1076.32 -1096.09 1124.66 -1181.97 1159.92C-1174.31 1156.77 -1166.58 1153.57 -1158.92 1150.43C-1185.43 1161.11 -1212.33 1170.52 -1239.62 1178.66C-1286.15 1192.47 -1321.42 1242.97 -1306.19 1292.15C-1291.7 1338.86 -1242.57 1373.54 -1192.62 1358.67Z" fill={fill2}/>
    <path d="M-51.3671 273.572C-65.4296 252.016 -66.2328 230.424 -45.1455 216.749C-24.4047 203.273 -4.85255 212.538 9.17328 234.232C23.0491 255.665 24.0389 277.379 3.03827 291.005C-17.7892 304.531 -37.2047 295.302 -51.3671 273.572ZM-29.0332 272.456C-27.5002 274.111 -26.028 273.261 -26.162 271.029L-28.3709 229.003C-28.4647 227.441 -29.2879 226.415 -30.8869 226.645C-33.019 226.952 -34.9876 227.743 -37.1027 229.079C-47.7512 236.035 -45.5617 248.628 -35.8457 263.456C-33.7091 266.957 -31.476 269.825 -29.0332 272.456ZM-10.6873 281.032C-8.74187 280.601 -6.85987 279.861 -4.91801 278.625C5.5573 271.768 3.36782 259.176 -6.2616 244.298C-8.33481 241.107 -10.3812 238.362 -12.5874 235.941C-14.1204 234.286 -15.6427 235.049 -15.5087 237.281L-13.3266 278.861C-13.2828 280.336 -12.2364 281.349 -10.6873 281.032Z" fill={fill1}/>
    <path d="M37.3493 266.231L31.7359 256.309C25.3725 245.087 31.6451 233.151 37.6616 222.172C43.1986 212.163 48.1295 202.503 43.4161 194.139C40.5161 189.116 35.3645 185.393 28.6095 189.293C21.0251 193.557 20.7305 199.847 24.3939 206.392C24.7439 206.998 24.561 207.681 23.9547 208.031L10.1349 215.895C9.52869 216.245 8.89572 216.148 8.54572 215.542C0.782319 201.895 3.92557 186.34 21.3461 176.513C35.6855 168.349 52.0876 172.159 58.851 184.073C68.2778 200.801 59.3446 216.928 52.2157 228.781C47.9803 235.845 44.7073 241.776 46.8073 245.413L81.1653 225.923C81.7716 225.573 82.4545 225.756 82.8045 226.362L88.8679 237.064C89.2179 237.671 89.035 238.354 88.4288 238.704L38.9885 266.671C38.3823 267.021 37.6993 266.837 37.3493 266.231Z" fill={fill1}/>
    <path d="M81.1631 219.921L77.7461 213.003C77.4961 212.57 77.4559 211.9 77.4657 211.317L86.5692 144.285C86.6656 143.652 86.9986 143.229 87.5682 143.015L104.326 134.841C104.982 134.578 105.665 134.761 105.929 135.417L128.475 181.867L140.443 175.997C141.099 175.733 141.782 175.916 142.045 176.572L147.416 187.675C147.679 188.331 147.496 189.014 146.84 189.277L134.872 195.148L142.433 210.643C142.696 211.3 142.513 211.983 141.857 212.246L127.451 219.293C126.794 219.557 126.111 219.374 125.848 218.718L118.287 203.222L82.5925 220.597C82.1595 220.847 81.4765 220.664 81.1631 219.921ZM112.063 189.841L98.5182 161.981L93.6095 198.879L112.063 189.841Z" fill={fill1}/>
    <path d="M319.94 112.893L335.423 108.111C336.216 107.884 336.775 108.253 336.916 109.096C339.9 127.465 330.884 144.448 310.607 150.728C284.571 158.832 266.633 144.362 259.146 120.395C251.609 96.3406 257.998 74.4074 284.294 66.1536C305.537 59.5471 321.464 68.1339 329.465 84.992C329.829 85.8214 329.682 86.3678 328.803 86.6446L313.32 91.4269C312.613 91.6037 312.067 91.4573 311.667 90.7645C305.673 78.983 296.948 77.6698 288.723 80.2244C275.878 84.1763 270.895 95.9447 276.821 114.809C282.647 133.5 293.42 140.559 306.265 136.607C314.489 134.053 320.874 127.711 319.041 114.336C318.9 113.493 319.233 113.07 319.94 112.893Z" fill={fill1}/>
    <path d="M354.777 47.6325C354.774 46.8263 355.38 46.4763 355.949 46.2629L378.112 40.0492C378.731 39.9224 379.314 39.9323 379.801 40.5751L433.011 112.738C433.548 113.467 433.228 114.114 432.435 114.341L415.426 119.08C414.807 119.207 414.124 119.024 413.737 118.554L402.315 102.17L367.367 111.84L366.054 131.766C366.058 132.572 365.452 132.922 364.882 133.135L347.873 137.875C347.08 138.101 346.434 137.782 346.554 136.789L354.777 47.6325ZM370.898 57.9551L368.315 96.2812L393.216 89.4104L371.208 57.8916L370.898 57.9551Z" fill={fill1}/>
    <path d="M400.766 33.8939L467.307 16.1458C468.013 15.969 468.659 16.2886 468.836 16.9948L472.015 28.9005C472.191 29.6067 471.872 30.2531 471.166 30.4299L446.821 36.8642L464.909 104.594C465.086 105.3 464.767 105.947 464.06 106.124L448.624 110.186C447.918 110.363 447.271 110.043 447.094 109.337L429.006 41.6073L404.662 48.0414C403.955 48.2182 403.309 47.8987 403.132 47.1925L399.953 35.2868C399.69 34.6306 400.06 34.0707 400.766 33.8939Z" fill={fill1}/>
    <path d="M476.748 13.6967L492.135 9.54738C492.842 9.37059 493.488 9.69025 493.665 10.3965L511.839 78.0764L551.83 67.3429C552.537 67.1661 553.183 67.4857 553.36 68.1919L556.539 80.0976C556.716 80.8038 556.396 81.4502 555.69 81.627L499.036 96.9001C498.33 97.0769 497.683 96.7572 497.507 96.051L475.813 15.2761C475.673 14.4333 476.042 13.8735 476.748 13.6967Z" fill={fill1}/>
    <path d="M542.173 40.8172C535.179 16.1032 542.365 -5.25052 568.528 -12.7347C594.295 -20.1056 611.859 -5.88246 618.953 19.0048C625.984 43.5822 618.848 65.0226 592.685 72.5068C566.782 79.8411 549.181 65.7545 542.173 40.8172ZM588.653 58.3226C601.958 54.5671 606.331 42.3425 601.202 24.0576C596.072 5.77275 585.779 -2.25616 572.56 1.44936C559.256 5.20487 554.709 17.5295 559.925 35.7644C565.055 54.0493 575.435 62.0281 588.653 58.3226Z" fill={fill1}/>
    <path d="M676.239 -16.3752C670.296 -25.0685 663.383 -26.0425 654.985 -23.3879C643.429 -19.603 638.533 -7.88454 644.682 10.9664C650.681 29.5574 661.627 36.5167 673.183 32.7318C681.184 30.1906 687.689 25.8576 690.079 19.3971L687.252 11.0993L669.367 16.9218C668.66 17.0986 668.064 16.8656 667.837 16.0728L664.089 4.3804C663.912 3.67419 664.145 3.07778 664.938 2.85099L698.293 -7.97694C698.999 -8.15374 699.595 -7.9208 699.822 -7.12798L712.751 32.8657C712.928 33.5719 712.695 34.1683 711.902 34.3951L709.66 35.1121C709.09 35.3255 708.061 35.3425 707.365 34.9363L696.012 28.6733L695.789 28.6868C693.766 36.783 688.548 43.1444 677.648 46.666C652.815 54.6528 634.753 40.3699 627.093 16.5022C619.333 -7.53858 625.363 -29.495 650.456 -37.6319C670.1 -44.0079 685.474 -37.18 694.097 -22.6442C694.46 -21.8148 694.314 -21.2683 693.434 -20.9915L677.878 -15.936C677.185 -15.536 676.589 -15.769 676.239 -16.3752Z" fill={fill1}/>
    <path d="M793.351 -31.3346L808.394 -37.4794C809.224 -37.8428 809.783 -37.4731 809.924 -36.6303C814.457 -18.5784 807.007 -0.882498 787.284 7.15603C761.964 17.5017 742.904 4.48836 733.449 -18.6891C723.893 -42.0397 728.37 -64.4854 753.913 -74.8445C774.552 -83.2964 791.118 -76.0024 800.505 -59.9443C800.955 -59.1649 800.808 -58.6186 799.979 -58.2552L784.936 -52.1104C784.28 -51.847 783.647 -51.9434 783.247 -52.6362C776.3 -63.8678 767.442 -64.4113 759.481 -61.2005C747.062 -56.1094 743.085 -43.9981 750.547 -25.674C757.909 -7.52313 769.297 -1.39682 781.716 -6.48797C789.677 -9.69874 795.488 -16.6334 792.552 -29.7185C792.412 -30.5614 792.695 -31.0712 793.351 -31.3346Z" fill={fill1}/>
    <path d="M198.113 150.089L183.244 147.935L193.041 136.505L195.109 121.686L206.539 131.483L221.408 133.637L211.56 144.981L209.543 159.887L198.113 150.089Z" fill={fill1}/>
    <path d="M-1049.34 1606.84C-656.801 1490.13 -416.476 1143.19 -148.842 859.343C-71.9307 777.757 9.3393 700.121 98.1413 631.53C91.5899 636.583 84.9518 641.686 78.4004 646.738C164.412 580.914 257.002 524.685 357.286 483.183C349.635 486.33 341.897 489.528 334.246 492.675C571.666 395.899 832.933 370.227 1060.74 247.595C1254.77 143.074 1416.11 -21.0866 1522.65 -213.544C1586.76 -329.307 1632.46 -456.151 1657.87 -585.944C1667.19 -633.59 1643.38 -688.432 1592.12 -699.826C1545.71 -710.212 1488.17 -685.074 1478.24 -634.076C1467.61 -579.676 1453.62 -526.105 1435.9 -473.608C1431.82 -461.67 1427.61 -449.769 1423.17 -437.854C1420.71 -431.12 1418.11 -424.423 1415.37 -417.763C1414.4 -415.24 1413.38 -412.805 1412.41 -410.282C1408.26 -399.684 1420.74 -429.868 1414.33 -414.967C1403.78 -390.634 1392.83 -366.411 1380.85 -342.753C1357.34 -296.271 1330.69 -251.438 1300.86 -208.699C1294.06 -198.882 1286.98 -189.138 1279.86 -179.482C1270.87 -167.249 1284.59 -185.68 1285.17 -186.476C1283.32 -183.677 1280.93 -181.024 1278.89 -178.348C1274.69 -173.033 1270.48 -167.718 1266.19 -162.353C1250.21 -142.622 1233.53 -123.521 1216.18 -104.962C1177.24 -63.423 1135.05 -25.0941 1089.97 9.82426C1096.52 4.77164 1103.16 -0.330958 1109.71 -5.38359C1035.58 51.6185 954.455 98.1087 868.024 133.807C875.676 130.659 883.414 127.462 891.065 124.314C767.13 174.853 636.642 205.041 507.902 240.456C372.422 277.799 238.668 323.73 117.422 395.925C-126.456 541.116 -309.122 758.93 -495.671 968.017C-596.236 1080.23 -700.019 1191.08 -819.787 1283.43C-813.235 1278.38 -806.597 1273.28 -800.046 1268.22C-873.683 1324.48 -952.814 1372.82 -1038.69 1408.08C-1031.04 1404.94 -1023.3 1401.74 -1015.65 1398.59C-1042.16 1409.28 -1069.05 1418.69 -1096.34 1426.82C-1142.87 1440.64 -1178.14 1491.14 -1162.92 1540.31C-1148.42 1587.02 -1099.3 1621.71 -1049.34 1606.84Z" fill={fill2}/>
    <path d="M61.663 595.549L54.9237 586.276C47.3113 575.891 52.1545 563.28 56.8782 551.661C61.1724 541.099 64.997 530.923 59.381 523.196C56.0114 518.56 50.4035 515.447 44.1351 519.99C37.0507 525.119 37.5625 531.405 41.9553 537.414C42.3919 537.97 42.2588 538.74 41.7026 539.176L28.8694 548.549C28.3132 548.985 27.6436 549.025 27.2436 548.333C17.9213 535.586 19.2656 519.914 35.3995 508.059C48.7023 498.3 65.4302 500.074 73.4926 511.238C84.8613 526.729 77.7308 543.779 72.0813 556.394C68.6789 563.901 66.189 570.188 68.5854 573.539L100.52 550.251C101.076 549.815 101.846 549.948 102.283 550.504L109.508 560.419C109.945 560.976 109.812 561.745 109.256 562.182L63.3753 595.715C62.8691 596.238 62.1496 596.191 61.663 595.549Z" fill={fill1}/>
    <path d="M91.9102 521.736C77.8478 500.18 77.0446 478.589 98.1318 464.913C118.873 451.437 138.425 460.702 152.451 482.396C166.326 503.829 167.316 525.543 146.316 539.169C125.488 552.695 106.073 543.466 91.9102 521.736ZM114.244 520.62C115.777 522.275 117.249 521.425 117.115 519.193L114.906 477.167C114.813 475.605 113.989 474.579 112.39 474.809C110.258 475.116 108.29 475.907 106.175 477.243C95.5262 484.199 97.7157 496.792 107.432 511.62C109.568 515.121 111.801 517.989 114.244 520.62ZM132.59 529.196C134.535 528.766 136.417 528.025 138.359 526.789C148.835 519.933 146.645 507.34 137.016 492.462C134.943 489.271 132.896 486.526 130.69 484.105C129.157 482.45 127.635 483.213 127.769 485.445L129.951 527.025C129.995 528.501 131.041 529.513 132.59 529.196Z" fill={fill1}/>
    <path d="M180.627 514.395L175.013 504.473C168.65 493.251 174.922 481.316 180.939 470.336C186.476 460.327 191.407 450.667 186.693 442.303C183.793 437.281 178.642 433.558 171.887 437.458C164.302 441.721 164.008 448.011 167.671 454.556C168.021 455.162 167.838 455.845 167.232 456.195L153.412 464.059C152.806 464.409 152.173 464.312 151.823 463.706C144.06 450.059 147.203 434.504 164.623 424.677C178.963 416.514 195.365 420.323 202.128 432.237C211.555 448.965 202.622 465.092 195.493 476.945C191.258 484.009 187.985 489.94 190.085 493.577L224.443 474.087C225.049 473.737 225.732 473.92 226.082 474.526L232.145 485.228C232.495 485.835 232.312 486.518 231.706 486.868L182.266 514.835C181.66 515.185 180.977 515.002 180.627 514.395Z" fill={fill1}/>
    <path d="M224.44 468.085L221.023 461.167C220.773 460.734 220.733 460.064 220.743 459.481L229.847 392.449C229.943 391.816 230.276 391.393 230.846 391.179L247.604 383.005C248.26 382.742 248.943 382.925 249.206 383.581L271.752 430.031L283.72 424.161C284.376 423.897 285.059 424.08 285.323 424.736L290.693 435.839C290.957 436.495 290.774 437.178 290.117 437.441L278.149 443.312L285.71 458.807C285.973 459.464 285.791 460.147 285.134 460.41L270.728 467.457C270.072 467.721 269.389 467.538 269.125 466.882L261.564 451.386L225.87 468.761C225.437 469.011 224.754 468.828 224.44 468.085ZM255.34 438.005L241.796 410.145L236.887 447.043L255.34 438.005Z" fill={fill1}/>
    <path d="M463.217 361.057L478.7 356.275C479.493 356.048 480.053 356.417 480.193 357.26C483.177 375.629 474.161 392.613 453.884 398.892C427.848 406.996 409.91 392.527 402.423 368.559C394.886 344.505 401.276 322.571 427.572 314.318C448.814 307.711 464.742 316.298 472.743 333.156C473.106 333.985 472.96 334.532 472.08 334.809L456.597 339.591C455.891 339.768 455.344 339.621 454.944 338.929C448.951 327.147 440.225 325.834 432 328.388C419.155 332.34 414.172 344.109 420.098 362.973C425.924 381.664 436.697 388.723 449.542 384.771C457.767 382.217 464.151 375.875 462.318 362.5C462.178 361.657 462.511 361.234 463.217 361.057Z" fill={fill1}/>
    <path d="M498.054 295.797C498.051 294.99 498.657 294.64 499.227 294.427L521.389 288.213C522.009 288.087 522.592 288.096 523.078 288.739L576.289 360.902C576.825 361.631 576.505 362.278 575.713 362.505L558.704 367.244C558.084 367.371 557.401 367.188 557.014 366.718L545.592 350.334L510.645 360.004L509.332 379.93C509.335 380.736 508.729 381.086 508.159 381.299L491.15 386.039C490.358 386.266 489.711 385.946 489.831 384.953L498.054 295.797ZM514.175 306.119L511.592 344.445L536.493 337.574L514.485 306.056L514.175 306.119Z" fill={fill1}/>
    <path d="M544.043 282.058L610.584 264.31C611.29 264.133 611.936 264.453 612.113 265.159L615.292 277.065C615.469 277.771 615.149 278.417 614.443 278.594L590.098 285.028L608.187 352.758C608.364 353.464 608.044 354.111 607.338 354.288L591.901 358.35C591.195 358.527 590.548 358.207 590.372 357.501L572.283 289.771L547.939 296.205C547.233 296.382 546.586 296.063 546.409 295.357L543.231 283.451C542.967 282.795 543.337 282.235 544.043 282.058Z" fill={fill1}/>
    <path d="M620.026 261.861L635.413 257.711C636.119 257.535 636.765 257.854 636.942 258.561L655.117 326.241L695.108 315.507C695.814 315.33 696.461 315.65 696.637 316.356L699.816 328.262C699.993 328.968 699.673 329.614 698.967 329.791L642.313 345.064C641.607 345.241 640.961 344.921 640.784 344.215L619.09 263.44C618.95 262.597 619.319 262.038 620.026 261.861Z" fill={fill1}/>
    <path d="M685.451 288.981C678.457 264.267 685.642 242.914 711.805 235.429C737.572 228.058 755.137 242.282 762.231 267.169C769.261 291.746 762.126 313.187 735.963 320.671C710.059 328.005 692.458 313.919 685.451 288.981ZM731.93 306.487C745.235 302.731 749.609 290.507 744.479 272.222C739.349 253.937 729.056 245.908 715.838 249.613C702.533 253.369 697.986 265.694 703.202 283.928C708.332 302.213 718.712 310.192 731.93 306.487Z" fill={fill1}/>
    <path d="M819.516 231.789C813.573 223.096 806.66 222.122 798.263 224.776C786.707 228.561 781.81 240.28 787.959 259.13C793.958 277.721 804.905 284.681 816.46 280.896C824.462 278.355 830.967 274.022 833.357 267.561L830.529 259.263L812.644 265.086C811.938 265.263 811.341 265.03 811.115 264.237L807.366 252.544C807.19 251.838 807.423 251.242 808.215 251.015L841.57 240.187C842.276 240.01 842.873 240.243 843.099 241.036L856.028 281.03C856.205 281.736 855.972 282.332 855.179 282.559L852.937 283.276C852.368 283.49 851.339 283.507 850.642 283.1L839.29 276.837L839.066 276.851C837.043 284.947 831.825 291.309 820.926 294.83C796.092 302.817 778.031 288.534 770.371 264.666C762.611 240.625 768.64 218.669 793.734 210.532C813.377 204.156 828.751 210.984 837.374 225.52C837.738 226.349 837.591 226.896 836.712 227.173L821.155 232.228C820.463 232.628 819.866 232.395 819.516 231.789Z" fill={fill1}/>
    <path d="M936.629 216.83L951.672 210.685C952.501 210.321 953.061 210.691 953.201 211.534C957.734 229.586 950.284 247.282 930.561 255.32C905.242 265.666 886.181 252.652 876.726 229.475C867.17 206.124 871.647 183.679 897.19 173.32C917.829 164.868 934.395 172.162 943.782 188.22C944.232 188.999 944.086 189.546 943.256 189.909L928.213 196.054C927.557 196.317 926.924 196.221 926.524 195.528C919.578 184.296 910.719 183.753 902.758 186.964C890.34 192.055 886.362 204.166 893.824 222.49C901.186 240.641 912.575 246.767 924.993 241.676C932.954 238.465 938.765 231.531 935.829 218.446C935.689 217.603 935.972 217.093 936.629 216.83Z" fill={fill1}/>
    <path d="M938.174 157.509L952.48 150.288C953.086 149.938 953.82 150.208 954.17 150.814L968.874 180.083L1000.36 164.215L985.654 134.946C985.304 134.34 985.573 133.607 986.179 133.257L1000.49 126.036C1001.09 125.686 1001.83 125.956 1002.18 126.562L1039.77 201.275C1040.12 201.882 1039.85 202.615 1039.24 202.965L1024.94 210.185C1024.33 210.535 1023.6 210.266 1023.25 209.659L1007.02 177.346L975.531 193.214L991.763 225.527C992.113 226.133 991.843 226.866 991.237 227.216L976.93 234.437C976.324 234.787 975.591 234.517 975.241 233.911L937.648 159.198C937.335 158.455 937.518 157.772 938.174 157.509Z" fill={fill1}/>
    <path d="M1054.61 193.171L1010.85 121.784C1010.5 121.178 1010.51 120.595 1011.15 120.109L1051.34 95.5194C1062.71 88.6096 1077.06 91.0636 1085.31 104.547C1093.1 117.251 1090.79 128.635 1082.94 136.05L1116.9 154.665C1117.73 155.108 1117.82 155.865 1116.96 156.365L1102.26 165.311C1101.74 165.611 1101.07 165.651 1100.56 165.368L1066.6 146.753L1052.95 155.099L1070.33 183.401C1070.68 184.007 1070.49 184.69 1069.94 185.127L1056.2 193.523C1055.5 193.923 1055.04 193.727 1054.61 193.171ZM1062.76 131.885C1069.5 127.762 1074.73 122.43 1069.82 114.526C1064.91 106.622 1057.88 108.837 1051.14 112.96L1033.72 123.593L1045.29 142.432L1062.76 131.885Z" fill={fill1}/>
    <path d="M1100.97 115.479C1085.83 94.6588 1085.2 72.1615 1107.13 56.15C1128.8 40.2886 1150.21 47.561 1165.4 68.4678C1180.43 89.1149 1181.25 111.735 1159.32 127.747C1137.6 143.522 1116.3 136.423 1100.97 115.479ZM1150.67 115.766C1161.79 107.617 1161.77 94.5812 1150.55 79.3499C1139.42 64.0686 1127.03 60.0181 1115.87 68.0805C1104.75 76.2296 1104.69 89.3157 1115.82 104.597C1127.04 119.828 1139.51 123.829 1150.67 115.766Z" fill={fill1}/>
    <path d="M-14.7219 614.244L-29.4195 617.187L-23.987 603.196L-26.9801 588.412L-12.9395 593.931L1.75807 590.988L-3.67439 604.979L-0.731362 619.676L-14.7219 614.244Z" fill={fill1}/>
    <path d="M341.39 398.253L326.521 396.099L336.319 384.669L338.386 369.85L349.816 379.647L364.685 381.801L354.838 393.145L352.82 408.051L341.39 398.253Z" fill={fill1}/>
    <path d="M-906.061 1855C-513.523 1738.3 -273.198 1391.35 -5.56451 1107.51C71.3466 1025.92 152.617 948.284 241.419 879.693C234.867 884.746 228.229 889.849 221.678 894.901C307.689 829.077 400.279 772.848 500.564 731.346C492.912 734.493 485.174 737.691 477.523 740.838C714.943 644.062 976.21 618.39 1204.01 495.758C1398.05 391.237 1559.38 227.077 1665.93 34.6193C1730.04 -81.1437 1775.74 -207.988 1801.15 -337.781C1810.47 -385.427 1786.66 -440.269 1735.4 -451.663C1688.98 -462.049 1631.44 -436.911 1621.51 -385.913C1610.89 -331.513 1596.9 -277.942 1579.17 -225.445C1575.1 -213.507 1570.88 -201.606 1566.45 -189.691C1563.98 -182.957 1561.38 -176.26 1558.65 -169.6C1557.68 -167.077 1556.66 -164.642 1555.69 -162.119C1551.53 -151.521 1564.01 -181.705 1557.61 -166.804C1547.06 -142.471 1536.1 -118.247 1524.13 -94.5901C1500.62 -48.1083 1473.96 -3.2748 1444.14 39.4644C1437.33 49.2811 1430.26 59.0246 1423.13 68.6815C1414.14 80.9143 1427.87 62.4836 1428.45 61.6871C1426.6 64.4862 1424.2 67.1388 1422.17 69.8146C1417.96 75.1297 1413.76 80.4448 1409.47 85.8098C1393.49 105.541 1376.81 124.642 1359.46 143.201C1320.51 184.74 1278.33 223.069 1233.25 257.987C1239.8 252.935 1246.43 247.832 1252.99 242.78C1178.86 299.782 1097.73 346.272 1011.3 381.97C1018.95 378.822 1026.69 375.625 1034.34 372.478C910.407 423.016 779.92 453.204 651.179 488.619C515.7 525.962 381.945 571.893 260.699 644.088C16.8211 789.279 -165.844 1007.09 -352.394 1216.18C-452.958 1328.4 -556.742 1439.24 -676.509 1531.6C-669.958 1526.54 -663.32 1521.44 -656.768 1516.39C-730.406 1572.64 -809.537 1620.99 -895.411 1656.25C-887.76 1653.1 -880.022 1649.9 -872.37 1646.75C-898.879 1657.44 -925.777 1666.85 -953.066 1674.98C-999.593 1688.8 -1034.87 1739.3 -1019.64 1788.48C-1005.14 1835.19 -956.019 1869.87 -906.061 1855Z" fill={fill2}/>
    <path d="M-102.193 1011.34L-91.106 999.742C-90.5998 999.218 -89.9302 999.178 -89.407 999.684L-38.8695 1048.22L-10.1517 1018.36C-9.6455 1017.84 -8.97588 1017.8 -8.45267 1018.3L0.391829 1026.82C0.915034 1027.33 0.95529 1028 0.449072 1028.52L-40.1582 1070.79C-40.6644 1071.31 -41.334 1071.35 -41.8572 1070.84L-102.186 1012.95C-102.609 1012.62 -102.699 1011.86 -102.193 1011.34Z" fill={fill1}/>
    <path d="M-52.9677 960.401L-10.4248 918.287C-9.91861 917.764 -9.199 917.81 -8.67579 918.317L-0.0411399 927.072C0.482065 927.579 0.435634 928.298 -0.0705839 928.821L-30.4374 958.824L-15.7475 973.668L11.9517 946.244C12.4579 945.721 13.1776 945.768 13.7008 946.274L22.3354 955.03C22.8586 955.536 22.8122 956.255 22.306 956.779L-5.39313 984.202L9.29673 999.046L39.6636 969.043C40.1698 968.52 40.8895 968.566 41.4127 969.072L50.0473 977.828C50.5705 978.334 50.5241 979.054 50.0179 979.577L7.47509 1021.69C6.96888 1022.21 6.24926 1022.17 5.72606 1021.66L-53.0837 962.2C-53.5203 961.644 -53.4739 960.924 -52.9677 960.401Z" fill={fill1}/>
    <path d="M36.8087 955.1C36.2855 954.594 36.3819 953.961 36.9747 953.388L48.6481 942.607C49.1543 942.083 49.9105 941.993 50.4837 942.586C55.4694 948.022 63.8048 948.059 72.3775 940.107C82.6055 930.622 82.1704 924.869 78.2812 920.533C73.4956 915.444 66.6272 918.947 58.35 923.611C44.69 931.151 27.4793 940.741 15.2152 927.499C4.82067 916.295 7.74697 899.364 22.2613 885.903C36.0961 873.066 53.499 870.409 64.2801 882.082C64.8033 882.588 64.7569 883.308 64.2506 883.831L52.4907 894.662C51.9844 895.185 51.3148 895.225 50.7416 894.633C46.4292 889.963 39.7062 889.919 32.2325 896.774C25.4017 903.143 24.3546 910.329 27.8072 914.109C32.4062 919.075 40.2675 915.691 48.9644 910.554C62.0414 903.004 78.3226 893.604 90.7733 906.97C101.031 918.137 97.6291 936.844 82.3488 950.978C67.4515 964.775 48.4996 967.749 36.8087 955.1Z" fill={fill1}/>
    <path d="M204.94 843.712L198.201 834.439C190.589 824.054 195.432 811.443 200.156 799.824C204.45 789.262 208.274 779.087 202.658 771.359C199.289 766.723 193.681 763.61 187.412 768.153C180.328 773.282 180.84 779.568 185.233 785.577C185.669 786.133 185.536 786.903 184.98 787.339L172.147 796.712C171.591 797.148 170.921 797.189 170.521 796.496C161.199 783.749 162.543 768.077 178.677 756.222C191.98 746.463 208.708 748.237 216.77 759.401C228.139 774.893 221.008 791.942 215.359 804.557C211.956 812.064 209.466 818.351 211.863 821.702L243.797 798.414C244.354 797.978 245.123 798.111 245.56 798.667L252.786 808.583C253.222 809.139 253.089 809.908 252.533 810.345L206.653 843.878C206.146 844.401 205.427 844.355 204.94 843.712Z" fill={fill1}/>
    <path d="M235.188 769.9C221.125 748.343 220.322 726.752 241.409 713.076C262.15 699.6 281.702 708.865 295.728 730.559C309.604 751.992 310.594 773.707 289.593 787.332C268.766 800.858 249.35 791.63 235.188 769.9ZM257.521 768.783C259.055 770.438 260.527 769.588 260.393 767.356L258.184 725.33C258.09 723.768 257.267 722.742 255.668 722.972C253.536 723.28 251.567 724.07 249.452 725.406C238.803 732.363 240.993 744.955 250.709 759.784C252.846 763.284 255.079 766.152 257.521 768.783ZM275.867 777.359C277.813 776.929 279.695 776.188 281.637 774.952C292.112 768.096 289.923 755.503 280.293 740.625C278.22 737.434 276.174 734.689 273.967 732.268C272.434 730.613 270.912 731.376 271.046 733.608L273.228 775.188C273.272 776.664 274.318 777.676 275.867 777.359Z" fill={fill1}/>
    <path d="M323.904 762.558L318.291 752.636C311.927 741.414 318.2 729.479 324.216 718.499C329.753 708.49 334.684 698.83 329.971 690.467C327.071 685.444 321.919 681.721 315.164 685.621C307.58 689.884 307.285 696.174 310.949 702.719C311.299 703.325 311.116 704.008 310.509 704.358L296.69 712.222C296.083 712.572 295.45 712.475 295.1 711.869C287.337 698.223 290.48 682.667 307.901 672.84C322.24 664.677 338.642 668.486 345.406 680.4C354.832 697.128 345.899 713.255 338.77 725.108C334.535 732.172 331.262 738.103 333.362 741.74L367.72 722.25C368.326 721.9 369.009 722.083 369.359 722.689L375.423 733.391C375.773 733.998 375.59 734.681 374.983 735.031L325.543 762.998C324.937 763.348 324.254 763.165 323.904 762.558Z" fill={fill1}/>
    <path d="M367.718 716.248L364.301 709.33C364.051 708.897 364.011 708.227 364.02 707.644L373.124 640.612C373.22 639.979 373.553 639.556 374.123 639.342L390.881 631.168C391.537 630.905 392.22 631.088 392.484 631.744L415.029 678.194L426.997 672.324C427.654 672.06 428.337 672.243 428.6 672.9L433.971 684.002C434.234 684.658 434.051 685.341 433.395 685.604L421.427 691.475L428.987 706.971C429.251 707.627 429.068 708.31 428.412 708.573L414.005 715.621C413.349 715.884 412.666 715.701 412.403 715.045L404.842 699.549L369.147 716.924C368.714 717.174 368.031 716.991 367.718 716.248ZM398.618 686.168L385.073 658.308L380.164 695.206L398.618 686.168Z" fill={fill1}/>
    <path d="M606.494 609.22L621.977 604.438C622.77 604.211 623.33 604.581 623.47 605.423C626.455 623.792 617.438 640.776 597.162 647.055C571.125 655.159 553.187 640.69 545.7 616.722C538.164 592.668 544.553 570.735 570.849 562.481C592.092 555.874 608.019 564.461 616.02 581.319C616.383 582.149 616.237 582.695 615.358 582.972L599.874 587.754C599.168 587.931 598.622 587.784 598.222 587.092C592.228 575.31 583.502 573.997 575.278 576.552C562.433 580.503 557.449 592.272 563.375 611.136C569.202 629.827 579.974 636.887 592.819 632.935C601.044 630.38 607.429 624.038 605.595 610.663C605.455 609.82 605.788 609.397 606.494 609.22Z" fill={fill1}/>
    <path d="M641.332 543.96C641.328 543.153 641.934 542.803 642.504 542.59L664.666 536.376C665.286 536.25 665.869 536.259 666.356 536.902L719.566 609.065C720.102 609.795 719.783 610.441 718.99 610.668L701.981 615.407C701.361 615.534 700.678 615.351 700.292 614.881L688.869 598.498L653.922 608.167L652.609 628.093C652.613 628.899 652.006 629.249 651.437 629.462L634.428 634.202C633.635 634.429 632.989 634.109 633.108 633.116L641.332 543.96ZM657.453 554.282L654.87 592.608L679.77 585.738L657.763 554.219L657.453 554.282Z" fill={fill1}/>
    <path d="M687.321 530.221L753.861 512.473C754.567 512.296 755.214 512.616 755.391 513.322L758.569 525.228C758.746 525.934 758.426 526.58 757.72 526.757L733.376 533.191L751.464 600.921C751.641 601.627 751.321 602.274 750.615 602.451L735.178 606.513C734.472 606.69 733.826 606.371 733.649 605.664L715.561 537.934L691.216 544.369C690.51 544.545 689.864 544.226 689.687 543.52L686.508 531.614C686.245 530.958 686.614 530.398 687.321 530.221Z" fill={fill1}/>
    <path d="M763.303 510.024L778.69 505.875C779.396 505.698 780.043 506.017 780.219 506.724L798.394 574.404L838.385 563.67C839.091 563.493 839.738 563.813 839.915 564.519L843.094 576.425C843.27 577.131 842.951 577.777 842.244 577.954L785.591 593.227C784.884 593.404 784.238 593.084 784.061 592.378L762.368 511.603C762.227 510.76 762.597 510.201 763.303 510.024Z" fill={fill1}/>
    <path d="M828.728 537.144C821.734 512.43 828.92 491.077 855.083 483.592C880.849 476.222 898.414 490.445 905.508 515.332C912.539 539.909 905.403 561.35 879.24 568.834C853.337 576.168 835.735 562.082 828.728 537.144ZM875.208 554.65C888.512 550.894 892.886 538.67 887.756 520.385C882.627 502.1 872.333 494.071 859.115 497.777C845.81 501.532 841.263 513.857 846.48 532.092C851.609 550.376 861.99 558.355 875.208 554.65Z" fill={fill1}/>
    <path d="M962.794 479.952C956.851 471.259 949.938 470.285 941.54 472.939C929.984 476.724 925.087 488.443 931.237 507.293C937.236 525.885 948.182 532.844 959.738 529.059C967.739 526.518 974.244 522.185 976.634 515.724L973.806 507.426L955.921 513.249C955.215 513.426 954.619 513.193 954.392 512.4L950.644 500.708C950.467 500.001 950.7 499.405 951.493 499.178L984.847 488.35C985.553 488.173 986.15 488.406 986.377 489.199L999.306 529.193C999.483 529.899 999.25 530.495 998.457 530.722L996.215 531.439C995.645 531.653 994.616 531.67 993.919 531.263L982.567 525L982.344 525.014C980.321 533.11 975.102 539.472 964.203 542.993C939.369 550.98 921.308 536.697 913.648 512.829C905.888 488.789 911.918 466.832 937.011 458.695C956.655 452.319 972.029 459.147 980.652 473.683C981.015 474.512 980.869 475.059 979.989 475.336L964.433 480.391C963.74 480.791 963.144 480.558 962.794 479.952Z" fill={fill1}/>
    <path d="M1079.91 464.993L1094.95 458.848C1095.78 458.484 1096.34 458.854 1096.48 459.697C1101.01 477.749 1093.56 495.445 1073.84 503.483C1048.52 513.829 1029.46 500.816 1020 477.638C1010.45 454.287 1014.92 431.842 1040.47 421.483C1061.11 413.031 1077.67 420.325 1087.06 436.383C1087.51 437.162 1087.36 437.709 1086.53 438.072L1071.49 444.217C1070.83 444.48 1070.2 444.384 1069.8 443.691C1062.86 432.459 1054 431.916 1046.04 435.127C1033.62 440.218 1029.64 452.329 1037.1 470.653C1044.46 488.804 1055.85 494.93 1068.27 489.839C1076.23 486.628 1082.04 479.694 1079.11 466.609C1078.97 465.766 1079.25 465.256 1079.91 464.993Z" fill={fill1}/>
    <path d="M1081.45 405.672L1095.76 398.451C1096.36 398.101 1097.1 398.371 1097.45 398.977L1112.15 428.246L1143.64 412.378L1128.93 383.109C1128.58 382.503 1128.85 381.77 1129.46 381.42L1143.76 374.199C1144.37 373.849 1145.1 374.119 1145.45 374.725L1183.05 449.438C1183.4 450.045 1183.13 450.778 1182.52 451.128L1168.21 458.348C1167.61 458.698 1166.87 458.429 1166.52 457.822L1150.29 425.509L1118.81 441.377L1135.04 473.69C1135.39 474.297 1135.12 475.03 1134.51 475.38L1120.21 482.6C1119.6 482.95 1118.87 482.68 1118.52 482.074L1080.93 407.361C1080.61 406.618 1080.79 405.935 1081.45 405.672Z" fill={fill1}/>
    <path d="M128.555 862.407L113.858 865.35L119.29 851.359L116.297 836.575L130.338 842.094L145.035 839.151L139.603 853.142L142.546 867.839L128.555 862.407Z" fill={fill1}/>
    <path d="M484.667 646.416L469.798 644.262L479.596 632.832L481.663 618.013L493.094 627.81L507.963 629.965L498.115 641.308L496.098 656.214L484.667 646.416Z" fill={fill1}/>
    <path d="M-762.784 2103.16C-370.246 1986.46 -129.921 1639.51 137.713 1355.67C214.624 1274.08 295.894 1196.45 384.696 1127.86C378.145 1132.91 371.507 1138.01 364.955 1143.07C450.966 1077.24 543.557 1021.01 643.841 979.51C636.19 982.657 628.452 985.855 620.8 989.002C858.221 892.226 1119.49 866.554 1347.29 743.922C1541.33 639.401 1702.66 475.241 1809.21 282.783C1873.31 167.02 1919.02 40.1763 1944.42 -89.6164C1953.75 -137.263 1929.94 -192.105 1878.67 -203.499C1832.26 -213.885 1774.72 -188.747 1764.79 -137.749C1754.17 -83.3489 1740.18 -29.7776 1722.45 22.719C1718.37 34.657 1714.16 46.5583 1709.72 58.4731C1707.26 65.2068 1704.66 71.904 1701.92 78.5645C1700.96 81.0867 1699.94 83.5224 1698.97 86.0447C1694.81 96.6434 1707.29 66.4595 1700.88 81.36C1690.34 105.693 1679.38 129.917 1667.4 153.574C1643.9 200.056 1617.24 244.889 1587.42 287.628C1580.61 297.445 1573.54 307.189 1566.41 316.846C1557.42 329.078 1571.14 310.648 1571.72 309.851C1569.88 312.65 1567.48 315.303 1565.45 317.979C1561.24 323.294 1557.04 328.609 1552.74 333.974C1536.77 353.705 1520.08 372.806 1502.74 391.365C1463.79 432.904 1421.6 471.233 1376.52 506.151C1383.07 501.099 1389.71 495.996 1396.26 490.944C1322.13 547.946 1241.01 594.436 1154.58 630.134C1162.23 626.986 1169.97 623.789 1177.62 620.642C1053.68 671.18 923.197 701.368 794.456 736.783C658.977 774.126 525.223 820.057 403.976 892.252C160.098 1037.44 -22.567 1255.26 -209.116 1464.34C-309.681 1576.56 -413.465 1687.4 -533.232 1779.76C-526.68 1774.71 -520.042 1769.6 -513.491 1764.55C-587.129 1820.81 -666.259 1869.15 -752.134 1904.41C-744.482 1901.26 -736.744 1898.07 -729.093 1894.92C-755.601 1905.6 -782.5 1915.01 -809.789 1923.15C-856.315 1936.96 -891.59 1987.47 -876.362 2036.64C-861.865 2083.35 -812.741 2118.03 -762.784 2103.16Z" fill={fill2}/>
    <path d="M-31.5347 1466.32L-94.0978 1410.76C-94.621 1410.25 -94.6612 1409.58 -94.205 1408.97L-83.4182 1396.86C-82.9985 1396.38 -82.3789 1396.26 -81.7459 1396.35L-15.1563 1406.29L-15.0332 1406.1L-56.9618 1368.88C-57.485 1368.37 -57.5253 1367.7 -57.069 1367.09L-46.4554 1355.08C-45.9492 1354.55 -45.2796 1354.51 -44.6698 1354.97L17.8933 1410.53C18.4165 1411.04 18.4567 1411.71 18.0005 1412.32L7.21365 1424.43C6.79404 1424.91 6.17447 1425.03 5.54146 1424.94L-61.2347 1414.88L-61.3579 1415.06L-19.2926 1452.32C-18.7694 1452.83 -18.7293 1453.5 -19.1855 1454.11L-29.7991 1466.13C-30.2053 1466.82 -30.9249 1466.78 -31.5347 1466.32Z" fill={fill1}/>
    <path d="M-36.6929 1344.59L-26.0292 1332.66C-25.523 1332.13 -24.8534 1332.09 -24.2436 1332.55L37.9364 1388.45C38.4597 1388.96 38.4998 1389.62 38.0436 1390.23L27.38 1402.16C26.8738 1402.69 26.2041 1402.73 25.5943 1402.27L-36.5857 1346.37C-37.1589 1345.78 -37.1991 1345.11 -36.6929 1344.59Z" fill={fill1}/>
    <path d="M61.7176 1316.64L72.691 1304.64C73.2338 1303.98 73.8668 1304.08 74.4901 1304.76C86.3747 1319.15 87.0804 1338.37 72.7001 1354.06C54.1969 1374.21 31.445 1370.6 12.9998 1353.66C-5.63197 1336.58 -11.126 1314.47 7.50045 1294.13C22.5102 1277.73 40.6667 1277.18 55.9949 1287.73C56.7413 1288.22 56.868 1288.84 56.1886 1289.46L45.2152 1301.45C44.709 1301.98 44.0894 1302.1 43.4796 1301.65C32.3834 1294.43 24.1624 1297.79 18.361 1304.14C9.27586 1314.01 10.9323 1326.68 25.4918 1340.09C39.9514 1353.34 52.8045 1354 61.8896 1344.14C67.691 1337.78 70.0336 1329.04 61.7382 1318.47C61.115 1317.79 61.2114 1317.16 61.7176 1316.64Z" fill={fill1}/>
    <path d="M41.0845 1259.5L52.1714 1247.91C52.6776 1247.38 53.3472 1247.34 53.8704 1247.85L104.408 1296.38L133.126 1266.52C133.632 1266 134.301 1265.96 134.825 1266.47L143.669 1274.98C144.192 1275.49 144.233 1276.16 143.726 1276.68L103.119 1318.95C102.613 1319.47 101.943 1319.51 101.42 1319.01L41.0917 1261.12C40.6685 1260.78 40.5783 1260.03 41.0845 1259.5Z" fill={fill1}/>
    <path d="M90.3096 1208.56L132.853 1166.45C133.359 1165.93 134.078 1165.97 134.602 1166.48L143.236 1175.24C143.759 1175.74 143.713 1176.46 143.207 1176.99L112.84 1206.99L127.53 1221.83L155.229 1194.41C155.735 1193.89 156.455 1193.93 156.978 1194.44L165.613 1203.19C166.136 1203.7 166.09 1204.42 165.583 1204.94L137.884 1232.37L152.574 1247.21L182.941 1217.21C183.447 1216.68 184.167 1216.73 184.69 1217.24L193.325 1225.99C193.848 1226.5 193.801 1227.22 193.295 1227.74L150.752 1269.85C150.246 1270.38 149.527 1270.33 149.003 1269.83L90.1936 1210.36C89.757 1209.81 89.8034 1209.09 90.3096 1208.56Z" fill={fill1}/>
    <path d="M180.086 1203.26C179.563 1202.76 179.659 1202.13 180.252 1201.55L191.925 1190.77C192.432 1190.25 193.188 1190.16 193.761 1190.75C198.747 1196.19 207.082 1196.22 215.655 1188.27C225.883 1178.79 225.448 1173.03 221.559 1168.7C216.773 1163.61 209.905 1167.11 201.627 1171.77C187.967 1179.32 170.757 1188.91 158.492 1175.66C148.098 1164.46 151.024 1147.53 165.539 1134.07C179.373 1121.23 196.776 1118.57 207.557 1130.25C208.081 1130.75 208.034 1131.47 207.528 1132L195.768 1142.83C195.262 1143.35 194.592 1143.39 194.019 1142.8C189.707 1138.13 182.984 1138.08 175.51 1144.94C168.679 1151.31 167.632 1158.49 171.085 1162.27C175.684 1167.24 183.545 1163.86 192.242 1158.72C205.319 1151.17 221.6 1141.77 234.051 1155.13C244.309 1166.3 240.906 1185.01 225.626 1199.14C210.729 1212.94 191.777 1215.91 180.086 1203.26Z" fill={fill1}/>
    <path d="M348.218 1091.88L341.478 1082.6C333.866 1072.22 338.709 1059.61 343.433 1047.99C347.727 1037.43 351.552 1027.25 345.936 1019.52C342.566 1014.89 336.958 1011.77 330.69 1016.32C323.605 1021.45 324.117 1027.73 328.51 1033.74C328.947 1034.3 328.813 1035.07 328.257 1035.5L315.424 1044.88C314.868 1045.31 314.198 1045.35 313.798 1044.66C304.476 1031.91 305.82 1016.24 321.954 1004.39C335.257 994.627 351.985 996.401 360.047 1007.57C371.416 1023.06 364.285 1040.11 358.636 1052.72C355.234 1060.23 352.744 1066.52 355.14 1069.87L387.075 1046.58C387.631 1046.14 388.401 1046.27 388.837 1046.83L396.063 1056.75C396.5 1057.3 396.367 1058.07 395.81 1058.51L349.93 1092.04C349.424 1092.57 348.704 1092.52 348.218 1091.88Z" fill={fill1}/>
    <path d="M378.465 1018.06C364.402 996.507 363.599 974.916 384.687 961.24C405.427 947.764 424.979 957.029 439.005 978.723C452.881 1000.16 453.871 1021.87 432.87 1035.5C412.043 1049.02 392.627 1039.79 378.465 1018.06ZM400.799 1016.95C402.332 1018.6 403.804 1017.75 403.67 1015.52L401.461 973.494C401.367 971.932 400.544 970.906 398.945 971.136C396.813 971.444 394.844 972.234 392.729 973.57C382.081 980.527 384.27 993.119 393.986 1007.95C396.123 1011.45 398.356 1014.32 400.799 1016.95ZM419.145 1025.52C421.09 1025.09 422.972 1024.35 424.914 1023.12C435.389 1016.26 433.2 1003.67 423.57 988.789C421.497 985.598 419.451 982.853 417.245 980.432C415.712 978.777 414.189 979.54 414.323 981.772L416.505 1023.35C416.549 1024.83 417.596 1025.84 419.145 1025.52Z" fill={fill1}/>
    <path d="M467.181 1010.72L461.568 1000.8C455.204 989.578 461.477 977.643 467.494 966.664C473.031 956.654 477.961 946.994 473.248 938.631C470.348 933.608 465.197 929.885 458.442 933.785C450.857 938.048 450.563 944.338 454.226 950.883C454.576 951.489 454.393 952.172 453.787 952.522L439.967 960.386C439.361 960.736 438.728 960.639 438.378 960.033C430.614 946.387 433.758 930.831 451.178 921.004C465.518 912.841 481.92 916.65 488.683 928.565C498.11 945.292 489.177 961.419 482.048 973.272C477.812 980.336 474.539 986.267 476.639 989.904L510.997 970.414C511.604 970.064 512.287 970.247 512.637 970.853L518.7 981.556C519.05 982.162 518.867 982.845 518.261 983.195L468.821 1011.16C468.214 1011.51 467.531 1011.33 467.181 1010.72Z" fill={fill1}/>
    <path d="M510.995 964.412L507.578 957.494C507.328 957.061 507.288 956.391 507.298 955.808L516.401 888.776C516.498 888.143 516.831 887.72 517.4 887.506L534.158 879.332C534.815 879.069 535.497 879.252 535.761 879.908L558.307 926.358L570.275 920.488C570.931 920.224 571.614 920.407 571.877 921.064L577.248 932.166C577.511 932.822 577.328 933.505 576.672 933.768L564.704 939.639L572.265 955.135C572.528 955.791 572.345 956.474 571.689 956.737L557.283 963.785C556.626 964.048 555.943 963.865 555.68 963.209L548.119 947.713L512.425 965.088C511.992 965.338 511.309 965.155 510.995 964.412ZM541.895 934.333L528.35 906.472L523.442 943.37L541.895 934.333Z" fill={fill1}/>
    <path d="M749.772 857.384L765.255 852.602C766.048 852.375 766.607 852.745 766.748 853.587C769.732 871.956 760.716 888.94 740.439 895.219C714.403 903.323 696.465 888.854 688.978 864.886C681.441 840.832 687.83 818.899 714.126 810.645C735.369 804.038 751.296 812.625 759.297 829.483C759.661 830.313 759.514 830.859 758.635 831.136L743.152 835.918C742.446 836.095 741.899 835.949 741.499 835.256C735.505 823.474 726.78 822.161 718.555 824.716C705.71 828.667 700.727 840.436 706.653 859.3C712.479 877.991 723.252 885.051 736.097 881.099C744.321 878.544 750.706 872.202 748.873 858.827C748.732 857.984 749.065 857.561 749.772 857.384Z" fill={fill1}/>
    <path d="M784.609 792.124C784.606 791.318 785.212 790.968 785.781 790.754L807.944 784.54C808.563 784.414 809.146 784.424 809.633 785.066L862.843 857.229C863.38 857.959 863.06 858.605 862.267 858.832L845.258 863.571C844.639 863.698 843.956 863.515 843.569 863.046L832.147 846.662L797.199 856.331L795.886 876.257C795.89 877.063 795.284 877.413 794.714 877.626L777.705 882.366C776.912 882.593 776.266 882.273 776.386 881.28L784.609 792.124ZM800.73 802.446L798.147 840.772L823.048 833.902L801.04 802.383L800.73 802.446Z" fill={fill1}/>
    <path d="M830.598 778.385L897.139 760.637C897.845 760.46 898.491 760.78 898.668 761.486L901.847 773.392C902.023 774.098 901.704 774.744 900.998 774.921L876.653 781.355L894.742 849.085C894.918 849.792 894.599 850.438 893.892 850.615L878.456 854.677C877.75 854.854 877.103 854.535 876.926 853.828L858.838 786.099L834.494 792.533C833.787 792.709 833.141 792.39 832.964 791.684L829.785 779.778C829.522 779.122 829.892 778.562 830.598 778.385Z" fill={fill1}/>
    <path d="M906.58 758.188L921.967 754.039C922.674 753.862 923.32 754.181 923.497 754.888L941.672 822.568L981.663 811.834C982.369 811.657 983.015 811.977 983.192 812.683L986.371 824.589C986.548 825.295 986.228 825.941 985.522 826.118L928.868 841.391C928.162 841.568 927.515 841.248 927.339 840.542L905.645 759.767C905.505 758.925 905.874 758.365 906.58 758.188Z" fill={fill1}/>
    <path d="M972.005 785.308C965.011 760.594 972.197 739.241 998.36 731.756C1024.13 724.386 1041.69 738.609 1048.79 763.496C1055.82 788.073 1048.68 809.514 1022.52 816.998C996.614 824.332 979.013 810.246 972.005 785.308ZM1018.49 802.814C1031.79 799.058 1036.16 786.834 1031.03 768.549C1025.9 750.264 1015.61 742.235 1002.39 745.941C989.088 749.696 984.541 762.021 989.757 780.256C994.887 798.54 1005.27 806.519 1018.49 802.814Z" fill={fill1}/>
    <path d="M1106.07 728.116C1100.13 719.423 1093.22 718.449 1084.82 721.103C1073.26 724.888 1068.36 736.607 1074.51 755.458C1080.51 774.049 1091.46 781.008 1103.02 777.223C1111.02 774.682 1117.52 770.349 1119.91 763.888L1117.08 755.59L1099.2 761.413C1098.49 761.59 1097.9 761.357 1097.67 760.564L1093.92 748.872C1093.74 748.165 1093.98 747.569 1094.77 747.342L1128.12 736.514C1128.83 736.337 1129.43 736.57 1129.65 737.363L1142.58 777.357C1142.76 778.063 1142.53 778.66 1141.73 778.886L1139.49 779.603C1138.92 779.817 1137.89 779.834 1137.2 779.427L1125.84 773.165L1125.62 773.178C1123.6 781.274 1118.38 787.636 1107.48 791.157C1082.65 799.144 1064.59 784.861 1056.93 760.993C1049.17 736.953 1055.2 714.996 1080.29 706.859C1099.93 700.483 1115.31 707.311 1123.93 721.847C1124.29 722.676 1124.15 723.223 1123.27 723.5L1107.71 728.555C1107.02 728.955 1106.42 728.722 1106.07 728.116Z" fill={fill1}/>
    <path d="M271.833 1110.57L257.135 1113.51L262.568 1099.52L259.575 1084.74L273.615 1090.26L288.313 1087.32L282.88 1101.31L285.823 1116L271.833 1110.57Z" fill={fill1}/>
    <path d="M627.945 894.58L613.076 892.426L622.873 880.996L624.941 866.177L636.371 875.975L651.24 878.129L641.392 889.472L639.375 904.378L627.945 894.58Z" fill={fill1}/>
    <path d="M-619.507 2351.33C-226.969 2234.62 13.3565 1887.68 280.99 1603.83C357.901 1522.25 439.171 1444.61 527.973 1376.02C521.422 1381.07 514.784 1386.18 508.232 1391.23C594.244 1325.4 686.834 1269.18 787.119 1227.67C779.467 1230.82 771.729 1234.02 764.078 1237.17C1001.5 1140.39 1262.76 1114.72 1490.57 992.085C1684.6 887.564 1845.94 723.404 1952.48 530.946C2016.59 415.183 2062.29 288.339 2087.7 158.547C2097.03 110.9 2073.22 56.0582 2021.95 44.6643C1975.54 34.2783 1918 59.4161 1908.07 110.414C1897.44 164.814 1883.46 218.386 1865.73 270.882C1861.65 282.82 1857.44 294.721 1853 306.636C1850.54 313.37 1847.94 320.067 1845.2 326.728C1844.23 329.25 1843.21 331.685 1842.25 334.208C1838.09 344.807 1850.57 314.623 1844.16 329.523C1833.61 353.856 1822.66 378.08 1810.68 401.737C1787.17 448.219 1760.52 493.052 1730.69 535.792C1723.89 545.608 1716.81 555.352 1709.69 565.009C1700.7 577.241 1714.42 558.811 1715 558.014C1713.15 560.813 1710.76 563.466 1708.72 566.142C1704.52 571.457 1700.31 576.772 1696.02 582.137C1680.05 601.868 1663.36 620.969 1646.02 639.528C1607.07 681.067 1564.88 719.396 1519.8 754.314C1526.35 749.262 1532.99 744.159 1539.54 739.107C1465.41 796.109 1384.29 842.599 1297.86 878.297C1305.51 875.149 1313.25 871.952 1320.9 868.805C1196.96 919.343 1066.47 949.531 937.734 984.946C802.254 1022.29 668.5 1068.22 547.254 1140.42C303.376 1285.61 120.71 1503.42 -65.839 1712.51C-166.404 1824.72 -270.187 1935.57 -389.955 2027.92C-383.403 2022.87 -376.765 2017.77 -370.214 2012.71C-443.851 2068.97 -522.982 2117.31 -608.856 2152.57C-601.205 2149.43 -593.467 2146.23 -585.815 2143.08C-612.324 2153.77 -639.223 2163.18 -666.512 2171.31C-713.038 2185.13 -748.312 2235.63 -733.085 2284.8C-718.588 2331.51 -669.464 2366.2 -619.507 2351.33Z" fill={fill2}/>
    <path d="M-121.711 1847.53L-110.811 1835.81C-110.305 1835.29 -109.635 1835.25 -109.112 1835.76L-85.1115 1857.93L-61.1765 1831.98L-85.1769 1809.81C-85.7001 1809.31 -85.7403 1808.64 -85.2341 1808.11L-74.3339 1796.39C-73.8277 1795.87 -73.1581 1795.83 -72.6349 1796.34L-11.2074 1853.13C-10.6842 1853.64 -10.6441 1854.31 -11.1503 1854.83L-22.0505 1866.55C-22.5567 1867.08 -23.2263 1867.12 -23.7495 1866.61L-50.266 1842.08L-74.201 1868.02L-47.6846 1892.55C-47.1614 1893.06 -47.1212 1893.73 -47.6274 1894.25L-58.5276 1905.97C-59.0338 1906.5 -59.7034 1906.54 -60.2266 1906.03L-121.654 1849.23C-122.177 1848.73 -122.217 1848.06 -121.711 1847.53Z" fill={fill1}/>
    <path d="M-1.62934 1842.52L-64.0326 1786.64C-64.5558 1786.13 -64.6826 1785.51 -64.1764 1784.99L-32.7284 1749.86C-23.8665 1740.01 -9.41177 1738.24 2.41836 1748.73C13.4521 1758.64 14.5951 1770.22 9.15447 1779.6L46.9594 1787.68C47.9156 1787.94 48.179 1788.59 47.463 1789.35L35.9602 1802.23C35.5406 1802.7 34.8344 1802.88 34.288 1802.73L-3.51699 1794.65L-14.1806 1806.58L10.6261 1828.75C11.1493 1829.26 11.1895 1829.92 10.7333 1830.53L-0.0168855 1842.51C-0.573103 1842.95 -1.15613 1842.94 -1.62934 1842.52ZM-11.5634 1781.52C-6.31816 1775.6 -2.86224 1768.99 -9.72371 1762.9C-16.6352 1756.73 -22.7938 1760.86 -28.039 1766.78L-41.6399 1782.02L-25.1643 1796.76L-11.5634 1781.52Z" fill={fill1}/>
    <path d="M20.4835 1754.82C1.18212 1737.79 -4.24853 1715.98 13.7449 1695.54C31.492 1675.48 53.9036 1677.9 73.3417 1694.97C92.5065 1711.96 98.1603 1733.76 80.1168 1754.1C62.2466 1774.35 39.8849 1772.02 20.4835 1754.82ZM69.0099 1744.47C78.1549 1734.11 75.2457 1721.47 61.0496 1708.88C46.8534 1696.29 33.8736 1695.01 24.7287 1705.37C15.5837 1715.73 18.4562 1728.51 32.6024 1741.01C46.8851 1753.55 59.9016 1754.69 69.0099 1744.47Z" fill={fill1}/>
    <path d="M111.743 1714.48L49.1796 1658.92C48.6564 1658.41 48.6162 1657.75 49.0724 1657.14L59.8592 1645.02C60.2788 1644.55 60.8984 1644.42 61.5314 1644.51L128.121 1654.45L128.244 1654.26L86.3155 1617.04C85.7923 1616.54 85.7521 1615.87 86.2083 1615.26L96.822 1603.24C97.3282 1602.72 97.9978 1602.68 98.6076 1603.13L161.171 1658.7C161.694 1659.2 161.734 1659.87 161.278 1660.48L150.491 1672.6C150.071 1673.07 149.452 1673.2 148.819 1673.1L82.0427 1663.04L81.9195 1663.23L123.985 1700.49C124.508 1700.99 124.548 1701.66 124.092 1702.27L113.478 1714.29C113.072 1714.99 112.352 1714.94 111.743 1714.48Z" fill={fill1}/>
    <path d="M106.584 1592.75L117.248 1580.82C117.754 1580.3 118.424 1580.26 119.034 1580.71L181.214 1636.61C181.737 1637.12 181.777 1637.79 181.321 1638.4L170.657 1650.33C170.151 1650.85 169.481 1650.89 168.872 1650.43L106.692 1594.54C106.118 1593.94 106.078 1593.27 106.584 1592.75Z" fill={fill1}/>
    <path d="M204.995 1564.8L215.968 1552.81C216.511 1552.15 217.144 1552.24 217.767 1552.92C229.652 1567.31 230.358 1586.53 215.977 1602.22C197.474 1622.37 174.722 1618.77 156.277 1601.82C137.645 1584.75 132.151 1562.63 150.778 1542.29C165.788 1525.89 183.944 1525.34 199.272 1535.89C200.019 1536.38 200.145 1537 199.466 1537.62L188.493 1549.62C187.986 1550.14 187.367 1550.27 186.757 1549.81C175.661 1542.59 167.44 1545.95 161.638 1552.31C152.553 1562.17 154.21 1574.84 168.769 1588.26C183.229 1601.5 196.082 1602.16 205.167 1592.3C210.968 1585.95 213.311 1577.2 205.016 1566.64C204.392 1565.96 204.489 1565.32 204.995 1564.8Z" fill={fill1}/>
    <path d="M184.362 1507.67L195.449 1496.07C195.955 1495.55 196.625 1495.51 197.148 1496.01L247.685 1544.55L276.403 1514.69C276.909 1514.16 277.579 1514.12 278.102 1514.63L286.947 1523.15C287.47 1523.65 287.51 1524.32 287.004 1524.85L246.396 1567.11C245.89 1567.64 245.221 1567.68 244.697 1567.17L184.369 1509.28C183.946 1508.95 183.856 1508.19 184.362 1507.67Z" fill={fill1}/>
    <path d="M233.587 1456.73L276.13 1414.61C276.636 1414.09 277.356 1414.14 277.879 1414.64L286.514 1423.4C287.037 1423.91 286.99 1424.63 286.484 1425.15L256.117 1455.15L270.807 1470L298.506 1442.57C299.013 1442.05 299.732 1442.09 300.255 1442.6L308.89 1451.36C309.413 1451.86 309.367 1452.58 308.861 1453.11L281.162 1480.53L295.851 1495.37L326.218 1465.37C326.725 1464.85 327.444 1464.89 327.967 1465.4L336.602 1474.16C337.125 1474.66 337.079 1475.38 336.573 1475.9L294.03 1518.02C293.524 1518.54 292.804 1518.49 292.281 1517.99L233.471 1458.53C233.034 1457.97 233.081 1457.25 233.587 1456.73Z" fill={fill1}/>
    <path d="M323.363 1451.43C322.84 1450.92 322.937 1450.29 323.529 1449.71L335.203 1438.93C335.709 1438.41 336.465 1438.32 337.038 1438.91C342.024 1444.35 350.36 1444.39 358.932 1436.43C369.16 1426.95 368.725 1421.2 364.836 1416.86C360.05 1411.77 353.182 1415.27 344.905 1419.94C331.245 1427.48 314.034 1437.07 301.77 1423.83C291.375 1412.62 294.302 1395.69 308.816 1382.23C322.651 1369.39 340.054 1366.74 350.835 1378.41C351.358 1378.92 351.312 1379.63 350.805 1380.16L339.045 1390.99C338.539 1391.51 337.87 1391.55 337.296 1390.96C332.984 1386.29 326.261 1386.25 318.787 1393.1C311.956 1399.47 310.909 1406.66 314.362 1410.44C318.961 1415.4 326.822 1412.02 335.519 1406.88C348.596 1399.33 364.877 1389.93 377.328 1403.3C387.586 1414.46 384.184 1433.17 368.903 1447.31C354.006 1461.1 335.054 1464.08 323.363 1451.43Z" fill={fill1}/>
    <path d="M491.495 1340.04L484.756 1330.77C477.143 1320.38 481.987 1307.77 486.71 1296.15C491.004 1285.59 494.829 1275.41 489.213 1267.69C485.843 1263.05 480.236 1259.94 473.967 1264.48C466.883 1269.61 467.394 1275.9 471.787 1281.9C472.224 1282.46 472.091 1283.23 471.535 1283.67L458.701 1293.04C458.145 1293.48 457.476 1293.52 457.076 1292.82C447.753 1280.08 449.098 1264.4 465.232 1252.55C478.534 1242.79 495.262 1244.56 503.325 1255.73C514.693 1271.22 507.563 1288.27 501.913 1300.88C498.511 1308.39 496.021 1314.68 498.417 1318.03L530.352 1294.74C530.908 1294.3 531.678 1294.44 532.115 1294.99L539.341 1304.91C539.777 1305.47 539.644 1306.24 539.088 1306.67L493.207 1340.2C492.701 1340.73 491.982 1340.68 491.495 1340.04Z" fill={fill1}/>
    <path d="M521.742 1266.23C507.68 1244.67 506.877 1223.08 527.964 1209.4C548.705 1195.93 568.257 1205.19 582.283 1226.89C596.158 1248.32 597.148 1270.03 576.148 1283.66C555.32 1297.19 535.905 1287.96 521.742 1266.23ZM544.076 1265.11C545.609 1266.77 547.081 1265.92 546.947 1263.68L544.738 1221.66C544.645 1220.09 543.821 1219.07 542.222 1219.3C540.09 1219.61 538.122 1220.4 536.007 1221.73C525.358 1228.69 527.548 1241.28 537.264 1256.11C539.4 1259.61 541.633 1262.48 544.076 1265.11ZM562.422 1273.69C564.368 1273.26 566.25 1272.52 568.191 1271.28C578.667 1264.42 576.477 1251.83 566.848 1236.95C564.775 1233.76 562.728 1231.02 560.522 1228.6C558.989 1226.94 557.467 1227.7 557.601 1229.94L559.783 1271.51C559.827 1272.99 560.873 1274 562.422 1273.69Z" fill={fill1}/>
    <path d="M610.459 1258.89L604.845 1248.96C598.482 1237.74 604.754 1225.81 610.771 1214.83C616.308 1204.82 621.239 1195.16 616.525 1186.79C613.625 1181.77 608.474 1178.05 601.719 1181.95C594.134 1186.21 593.84 1192.5 597.503 1199.05C597.853 1199.65 597.67 1200.34 597.064 1200.69L583.244 1208.55C582.638 1208.9 582.005 1208.8 581.655 1208.2C573.892 1194.55 577.035 1178.99 594.455 1169.17C608.795 1161 625.197 1164.81 631.96 1176.73C641.387 1193.46 632.454 1209.58 625.325 1221.43C621.09 1228.5 617.817 1234.43 619.917 1238.07L654.275 1218.58C654.881 1218.23 655.564 1218.41 655.914 1219.02L661.977 1229.72C662.327 1230.32 662.144 1231.01 661.538 1231.36L612.098 1259.32C611.492 1259.67 610.809 1259.49 610.459 1258.89Z" fill={fill1}/>
    <path d="M654.272 1212.58L650.855 1205.66C650.605 1205.22 650.565 1204.55 650.575 1203.97L659.679 1136.94C659.775 1136.31 660.108 1135.88 660.678 1135.67L677.436 1127.5C678.092 1127.23 678.775 1127.41 679.038 1128.07L701.584 1174.52L713.552 1168.65C714.208 1168.39 714.891 1168.57 715.155 1169.23L720.525 1180.33C720.789 1180.99 720.606 1181.67 719.949 1181.93L707.981 1187.8L715.542 1203.3C715.805 1203.95 715.623 1204.64 714.966 1204.9L700.56 1211.95C699.904 1212.21 699.221 1212.03 698.957 1211.37L691.397 1195.88L655.702 1213.25C655.269 1213.5 654.586 1213.32 654.272 1212.58ZM685.172 1182.5L671.628 1154.64L666.719 1191.53L685.172 1182.5Z" fill={fill1}/>
    <path d="M893.049 1105.55L908.532 1100.76C909.325 1100.54 909.885 1100.91 910.025 1101.75C913.009 1120.12 903.993 1137.1 883.716 1143.38C857.68 1151.49 839.742 1137.02 832.255 1113.05C824.718 1088.99 831.108 1067.06 857.404 1058.81C878.647 1052.2 894.574 1060.79 902.575 1077.65C902.938 1078.48 902.792 1079.02 901.912 1079.3L886.429 1084.08C885.723 1084.26 885.176 1084.11 884.776 1083.42C878.783 1071.64 870.057 1070.32 861.832 1072.88C848.987 1076.83 844.004 1088.6 849.93 1107.46C855.756 1126.15 866.529 1133.21 879.374 1129.26C887.599 1126.71 893.983 1120.37 892.15 1106.99C892.01 1106.15 892.343 1105.72 893.049 1105.55Z" fill={fill1}/>
    <path d="M927.886 1040.29C927.883 1039.48 928.489 1039.13 929.059 1038.92L951.221 1032.7C951.841 1032.58 952.424 1032.59 952.91 1033.23L1006.12 1105.39C1006.66 1106.12 1006.34 1106.77 1005.54 1106.99L988.536 1111.73C987.916 1111.86 987.233 1111.68 986.846 1111.21L975.424 1094.82L940.477 1104.49L939.164 1124.42C939.167 1125.23 938.561 1125.58 937.992 1125.79L920.983 1130.53C920.19 1130.76 919.543 1130.44 919.663 1129.44L927.886 1040.29ZM944.007 1050.61L941.424 1088.94L966.325 1082.06L944.317 1050.55L944.007 1050.61Z" fill={fill1}/>
    <path d="M973.875 1026.55L1040.42 1008.8C1041.12 1008.62 1041.77 1008.94 1041.95 1009.65L1045.12 1021.55C1045.3 1022.26 1044.98 1022.91 1044.27 1023.08L1019.93 1029.52L1038.02 1097.25C1038.2 1097.95 1037.88 1098.6 1037.17 1098.78L1021.73 1102.84C1021.03 1103.02 1020.38 1102.7 1020.2 1101.99L1002.12 1034.26L977.771 1040.7C977.065 1040.87 976.418 1040.55 976.241 1039.85L973.063 1027.94C972.799 1027.28 973.169 1026.72 973.875 1026.55Z" fill={fill1}/>
    <path d="M1049.86 1006.35L1065.24 1002.2C1065.95 1002.02 1066.6 1002.34 1066.77 1003.05L1084.95 1070.73L1124.94 1060C1125.65 1059.82 1126.29 1060.14 1126.47 1060.85L1129.65 1072.75C1129.82 1073.46 1129.51 1074.1 1128.8 1074.28L1072.15 1089.55C1071.44 1089.73 1070.79 1089.41 1070.62 1088.71L1048.92 1007.93C1048.78 1007.09 1049.15 1006.53 1049.86 1006.35Z" fill={fill1}/>
    <path d="M415.11 1358.73L400.412 1361.68L405.845 1347.69L402.852 1332.9L416.893 1338.42L431.59 1335.48L426.158 1349.47L429.101 1364.17L415.11 1358.73Z" fill={fill1}/>
    <path d="M771.222 1142.74L756.353 1140.59L766.151 1129.16L768.218 1114.34L779.648 1124.14L794.517 1126.29L784.67 1137.64L782.652 1152.54L771.222 1142.74Z" fill={fill1}/>
    <path d="M-476.229 2599.49C-83.6914 2482.79 156.634 2135.84 424.268 1852C501.179 1770.41 582.449 1692.77 671.251 1624.18C664.699 1629.24 658.061 1634.34 651.51 1639.39C737.521 1573.57 830.112 1517.34 930.396 1475.84C922.744 1478.98 915.006 1482.18 907.355 1485.33C1144.78 1388.55 1406.04 1362.88 1633.85 1240.25C1827.88 1135.73 1989.22 971.568 2095.76 779.11C2159.87 663.348 2205.57 536.503 2230.98 406.711C2240.3 359.064 2216.49 304.222 2165.23 292.828C2118.82 282.442 2061.28 307.58 2051.35 358.578C2040.72 412.978 2026.73 466.55 2009.01 519.046C2004.93 530.984 2000.72 542.885 1996.28 554.8C1993.82 561.534 1991.22 568.231 1988.48 574.892C1987.51 577.414 1986.49 579.85 1985.52 582.372C1981.37 592.971 1993.85 562.787 1987.44 577.687C1976.89 602.02 1965.93 626.244 1953.96 649.901C1930.45 696.383 1903.8 741.216 1873.97 783.956C1867.17 793.772 1860.09 803.516 1852.96 813.173C1843.98 825.406 1857.7 806.975 1858.28 806.178C1856.43 808.977 1854.04 811.63 1852 814.306C1847.8 819.621 1843.59 824.936 1839.3 830.301C1823.32 850.032 1806.64 869.133 1789.29 887.692C1750.35 929.231 1708.16 967.56 1663.08 1002.48C1669.63 997.426 1676.27 992.323 1682.82 987.271C1608.69 1044.27 1527.56 1090.76 1441.13 1126.46C1448.78 1123.31 1456.52 1120.12 1464.17 1116.97C1340.24 1167.51 1209.75 1197.7 1081.01 1233.11C945.532 1270.45 811.777 1316.38 690.531 1388.58C446.653 1533.77 263.988 1751.58 77.4384 1960.67C-23.1265 2072.89 -126.91 2183.73 -246.677 2276.09C-240.126 2271.03 -233.488 2265.93 -226.936 2260.88C-300.574 2317.13 -379.705 2365.48 -465.579 2400.74C-457.928 2397.59 -450.19 2394.39 -442.538 2391.25C-469.047 2401.93 -495.945 2411.34 -523.234 2419.48C-569.761 2433.29 -605.035 2483.79 -589.808 2532.97C-575.311 2579.68 -526.187 2614.36 -476.229 2599.49Z" fill={fill2}/>
    <path d="M163.761 2002.98C144.459 1985.95 139.029 1964.14 157.022 1943.71C174.769 1923.65 197.181 1926.07 216.619 1943.13C235.784 1960.13 241.438 1981.92 223.394 2002.27C205.524 2022.52 183.162 2020.18 163.761 2002.98ZM212.287 1992.63C221.432 1982.27 218.523 1969.63 204.327 1957.04C190.131 1944.45 177.151 1943.17 168.006 1953.53C158.861 1963.89 161.734 1976.67 175.88 1989.17C190.162 2001.71 203.179 2002.85 212.287 1992.63Z" fill={fill1}/>
    <path d="M255.02 1962.65L192.457 1907.08C191.934 1906.58 191.894 1905.91 192.35 1905.3L203.137 1893.18C203.556 1892.71 204.176 1892.58 204.809 1892.68L271.398 1902.62L271.522 1902.43L229.593 1865.21C229.07 1864.7 229.029 1864.03 229.486 1863.42L240.099 1851.4C240.606 1850.88 241.275 1850.84 241.885 1851.3L304.448 1906.86C304.971 1907.37 305.011 1908.03 304.555 1908.64L293.768 1920.76C293.349 1921.23 292.729 1921.36 292.096 1921.27L225.32 1911.21L225.197 1911.39L267.262 1948.65C267.785 1949.16 267.825 1949.83 267.369 1950.44L256.756 1962.45C256.349 1963.15 255.63 1963.1 255.02 1962.65Z" fill={fill1}/>
    <path d="M249.862 1840.91L260.525 1828.98C261.032 1828.46 261.701 1828.42 262.311 1828.88L324.491 1884.78C325.014 1885.28 325.054 1885.95 324.598 1886.56L313.935 1898.49C313.429 1899.01 312.759 1899.06 312.149 1898.6L249.969 1842.7C249.396 1842.11 249.356 1841.44 249.862 1840.91Z" fill={fill1}/>
    <path d="M348.272 1812.96L359.246 1800.97C359.789 1800.31 360.422 1800.41 361.045 1801.09C372.929 1815.47 373.635 1834.69 359.255 1850.39C340.752 1870.54 318 1866.93 299.555 1849.98C280.923 1832.91 275.429 1810.8 294.055 1790.46C309.065 1774.06 327.221 1773.5 342.55 1784.05C343.296 1784.55 343.423 1785.17 342.743 1785.79L331.77 1797.78C331.264 1798.31 330.644 1798.43 330.034 1797.98C318.938 1790.76 310.717 1794.12 304.916 1800.47C295.831 1810.33 297.487 1823 312.047 1836.42C326.506 1849.66 339.359 1850.33 348.444 1840.46C354.246 1834.11 356.588 1825.37 348.293 1814.8C347.67 1814.12 347.766 1813.49 348.272 1812.96Z" fill={fill1}/>
    <path d="M327.639 1755.83L338.726 1744.23C339.232 1743.71 339.902 1743.67 340.425 1744.18L390.963 1792.71L419.68 1762.85C420.187 1762.33 420.856 1762.29 421.379 1762.79L430.224 1771.31C430.747 1771.82 430.787 1772.49 430.281 1773.01L389.674 1815.28C389.168 1815.8 388.498 1815.84 387.975 1815.33L327.646 1757.44C327.223 1757.11 327.133 1756.35 327.639 1755.83Z" fill={fill1}/>
    <path d="M376.864 1704.89L419.407 1662.78C419.913 1662.26 420.633 1662.3 421.156 1662.81L429.791 1671.56C430.314 1672.07 430.268 1672.79 429.761 1673.31L399.395 1703.32L414.085 1718.16L441.784 1690.74C442.29 1690.21 443.01 1690.26 443.533 1690.77L452.167 1699.52C452.691 1700.03 452.644 1700.75 452.138 1701.27L424.439 1728.69L439.129 1743.54L469.496 1713.53C470.002 1713.01 470.721 1713.06 471.245 1713.56L479.879 1722.32C480.403 1722.83 480.356 1723.55 479.85 1724.07L437.307 1766.18C436.801 1766.71 436.081 1766.66 435.558 1766.15L376.748 1706.69C376.312 1706.13 376.358 1705.42 376.864 1704.89Z" fill={fill1}/>
    <path d="M466.641 1699.59C466.118 1699.09 466.214 1698.45 466.807 1697.88L478.48 1687.1C478.986 1686.57 479.743 1686.48 480.316 1687.08C485.301 1692.51 493.637 1692.55 502.21 1684.6C512.437 1675.11 512.002 1669.36 508.113 1665.02C503.328 1659.94 496.459 1663.44 488.182 1668.1C474.522 1675.64 457.311 1685.23 445.047 1671.99C434.653 1660.79 437.579 1643.86 452.093 1630.39C465.928 1617.56 483.331 1614.9 494.112 1626.57C494.635 1627.08 494.589 1627.8 494.083 1628.32L482.323 1639.15C481.816 1639.68 481.147 1639.72 480.574 1639.12C476.261 1634.45 469.538 1634.41 462.065 1641.27C455.234 1647.63 454.187 1654.82 457.639 1658.6C462.238 1663.57 470.1 1660.18 478.796 1655.05C491.873 1647.5 508.155 1638.1 520.605 1651.46C530.863 1662.63 527.461 1681.34 512.181 1695.47C497.283 1709.27 478.332 1712.24 466.641 1699.59Z" fill={fill1}/>
    <path d="M634.772 1588.2L628.033 1578.93C620.421 1568.55 625.264 1555.93 629.988 1544.32C634.282 1533.75 638.106 1523.58 632.49 1515.85C629.121 1511.21 623.513 1508.1 617.245 1512.64C610.16 1517.77 610.672 1524.06 615.065 1530.07C615.501 1530.62 615.368 1531.39 614.812 1531.83L601.979 1541.2C601.423 1541.64 600.753 1541.68 600.353 1540.99C591.031 1528.24 592.375 1512.57 608.509 1500.71C621.812 1490.95 638.54 1492.73 646.602 1503.89C657.971 1519.38 650.84 1536.43 645.191 1549.05C641.788 1556.55 639.298 1562.84 641.695 1566.19L673.63 1542.91C674.186 1542.47 674.955 1542.6 675.392 1543.16L682.618 1553.07C683.054 1553.63 682.921 1554.4 682.365 1554.84L636.485 1588.37C635.978 1588.89 635.259 1588.85 634.772 1588.2Z" fill={fill1}/>
    <path d="M665.02 1514.39C650.957 1492.83 650.154 1471.24 671.241 1457.57C691.982 1444.09 711.534 1453.36 725.56 1475.05C739.436 1496.48 740.426 1518.2 719.425 1531.82C698.598 1545.35 679.182 1536.12 665.02 1514.39ZM687.354 1513.27C688.887 1514.93 690.359 1514.08 690.225 1511.85L688.016 1469.82C687.922 1468.26 687.099 1467.23 685.5 1467.46C683.368 1467.77 681.399 1468.56 679.284 1469.9C668.636 1476.85 670.825 1489.45 680.541 1504.27C682.678 1507.78 684.911 1510.64 687.354 1513.27ZM705.699 1521.85C707.645 1521.42 709.527 1520.68 711.469 1519.44C721.944 1512.59 719.755 1499.99 710.125 1485.12C708.052 1481.93 706.006 1479.18 703.799 1476.76C702.266 1475.1 700.744 1475.87 700.878 1478.1L703.06 1519.68C703.104 1521.15 704.15 1522.17 705.699 1521.85Z" fill={fill1}/>
    <path d="M753.736 1507.05L748.123 1497.13C741.759 1485.91 748.032 1473.97 754.048 1462.99C759.585 1452.98 764.516 1443.32 759.803 1434.96C756.903 1429.93 751.751 1426.21 744.996 1430.11C737.412 1434.38 737.117 1440.67 740.781 1447.21C741.131 1447.82 740.948 1448.5 740.341 1448.85L726.522 1456.71C725.915 1457.06 725.282 1456.97 724.932 1456.36C717.169 1442.71 720.312 1427.16 737.733 1417.33C752.072 1409.17 768.474 1412.98 775.238 1424.89C784.664 1441.62 775.731 1457.75 768.602 1469.6C764.367 1476.66 761.094 1482.59 763.194 1486.23L797.552 1466.74C798.158 1466.39 798.841 1466.57 799.191 1467.18L805.255 1477.88C805.605 1478.49 805.422 1479.17 804.815 1479.52L755.375 1507.49C754.769 1507.84 754.086 1507.66 753.736 1507.05Z" fill={fill1}/>
    <path d="M797.55 1460.74L794.133 1453.82C793.883 1453.39 793.843 1452.72 793.852 1452.14L802.956 1385.1C803.052 1384.47 803.385 1384.05 803.955 1383.83L820.713 1375.66C821.369 1375.4 822.052 1375.58 822.316 1376.24L844.861 1422.69L856.83 1416.81C857.486 1416.55 858.169 1416.73 858.432 1417.39L863.803 1428.49C864.066 1429.15 863.883 1429.83 863.227 1430.1L851.259 1435.97L858.819 1451.46C859.083 1452.12 858.9 1452.8 858.244 1453.06L843.837 1460.11C843.181 1460.38 842.498 1460.19 842.235 1459.54L834.674 1444.04L798.979 1461.42C798.546 1461.67 797.863 1461.48 797.55 1460.74ZM828.45 1430.66L814.905 1402.8L809.996 1439.7L828.45 1430.66Z" fill={fill1}/>
    <path d="M1036.33 1353.71L1051.81 1348.93C1052.6 1348.7 1053.16 1349.07 1053.3 1349.91C1056.29 1368.28 1047.27 1385.27 1026.99 1391.55C1000.96 1399.65 983.019 1385.18 975.532 1361.21C967.996 1337.16 974.385 1315.23 1000.68 1306.97C1021.92 1300.37 1037.85 1308.95 1045.85 1325.81C1046.22 1326.64 1046.07 1327.19 1045.19 1327.46L1029.71 1332.25C1029 1332.42 1028.45 1332.28 1028.05 1331.58C1022.06 1319.8 1013.33 1318.49 1005.11 1321.04C992.265 1324.99 987.281 1336.76 993.207 1355.63C999.034 1374.32 1009.81 1381.38 1022.65 1377.43C1030.88 1374.87 1037.26 1368.53 1035.43 1355.15C1035.29 1354.31 1035.62 1353.89 1036.33 1353.71Z" fill={fill1}/>
    <path d="M1071.16 1288.45C1071.16 1287.64 1071.77 1287.29 1072.34 1287.08L1094.5 1280.87C1095.12 1280.74 1095.7 1280.75 1096.19 1281.39L1149.4 1353.56C1149.93 1354.29 1149.61 1354.93 1148.82 1355.16L1131.81 1359.9C1131.19 1360.03 1130.51 1359.84 1130.12 1359.37L1118.7 1342.99L1083.75 1352.66L1082.44 1372.58C1082.44 1373.39 1081.84 1373.74 1081.27 1373.95L1064.26 1378.69C1063.47 1378.92 1062.82 1378.6 1062.94 1377.61L1071.16 1288.45ZM1087.28 1298.77L1084.7 1337.1L1109.6 1330.23L1087.59 1298.71L1087.28 1298.77Z" fill={fill1}/>
    <path d="M558.387 1606.9L543.69 1609.84L549.122 1595.85L546.129 1581.07L560.17 1586.59L574.867 1583.64L569.435 1597.63L572.378 1612.33L558.387 1606.9Z" fill={fill1}/>
    <path d="M914.5 1390.91L899.63 1388.75L909.428 1377.32L911.495 1362.5L922.926 1372.3L937.795 1374.46L927.947 1385.8L925.93 1400.7L914.5 1390.91Z" fill={fill1}/>
    <path d="M-332.952 2847.65C59.5859 2730.95 299.911 2384.01 567.545 2100.16C644.456 2018.57 725.726 1940.94 814.528 1872.35C807.977 1877.4 801.339 1882.5 794.787 1887.56C880.798 1821.73 973.389 1765.5 1073.67 1724C1066.02 1727.15 1058.28 1730.35 1050.63 1733.49C1288.05 1636.72 1549.32 1611.04 1777.12 1488.41C1971.16 1383.89 2132.49 1219.73 2239.04 1027.27C2303.15 911.511 2348.85 784.667 2374.25 654.874C2383.58 607.227 2359.77 552.385 2308.5 540.991C2262.09 530.605 2204.55 555.743 2194.62 606.741C2184 661.141 2170.01 714.713 2152.28 767.209C2148.21 779.147 2143.99 791.049 2139.56 802.963C2137.09 809.697 2134.49 816.394 2131.76 823.055C2130.79 825.577 2129.77 828.013 2128.8 830.535C2124.64 841.134 2137.12 810.95 2130.71 825.85C2120.17 850.183 2109.21 874.407 2097.24 898.064C2073.73 944.546 2047.07 989.379 2017.25 1032.12C2010.44 1041.94 2003.37 1051.68 1996.24 1061.34C1987.25 1073.57 2000.98 1055.14 2001.56 1054.34C1999.71 1057.14 1997.31 1059.79 1995.28 1062.47C1991.07 1067.78 1986.87 1073.1 1982.57 1078.46C1966.6 1098.19 1949.92 1117.3 1932.57 1135.85C1893.62 1177.39 1851.43 1215.72 1806.35 1250.64C1812.91 1245.59 1819.54 1240.49 1826.1 1235.43C1751.96 1292.44 1670.84 1338.93 1584.41 1374.62C1592.06 1371.48 1599.8 1368.28 1607.45 1365.13C1483.52 1415.67 1353.03 1445.86 1224.29 1481.27C1088.81 1518.62 955.055 1564.55 833.808 1636.74C589.93 1781.93 407.265 1999.75 220.716 2208.83C120.151 2321.05 16.3674 2431.89 -103.4 2524.25C-96.8484 2519.2 -90.2104 2514.09 -83.659 2509.04C-157.297 2565.3 -236.427 2613.64 -322.302 2648.9C-314.65 2645.75 -306.912 2642.56 -299.261 2639.41C-325.769 2650.09 -352.668 2659.5 -379.957 2667.64C-426.483 2681.45 -461.758 2731.96 -446.53 2781.13C-432.033 2827.84 -382.909 2862.53 -332.952 2847.65Z" fill={fill2}/>
    <path d="M520.142 1953.06L562.685 1910.94C563.191 1910.42 563.91 1910.46 564.434 1910.97L573.068 1919.73C573.591 1920.23 573.545 1920.95 573.039 1921.48L542.672 1951.48L557.362 1966.32L585.061 1938.9C585.567 1938.38 586.287 1938.42 586.81 1938.93L595.445 1947.68C595.968 1948.19 595.922 1948.91 595.415 1949.43L567.716 1976.86L582.406 1991.7L612.773 1961.7C613.279 1961.17 613.999 1961.22 614.522 1961.73L623.157 1970.48C623.68 1970.99 623.633 1971.71 623.127 1972.23L580.584 2014.34C580.078 2014.87 579.359 2014.82 578.835 2014.32L520.026 1954.85C519.589 1954.3 519.635 1953.58 520.142 1953.06Z" fill={fill1}/>
    <path d="M609.918 1947.75C609.395 1947.25 609.491 1946.62 610.084 1946.04L621.758 1935.26C622.264 1934.74 623.02 1934.65 623.593 1935.24C628.579 1940.68 636.914 1940.71 645.487 1932.76C655.715 1923.28 655.28 1917.52 651.391 1913.19C646.605 1908.1 639.737 1911.6 631.459 1916.27C617.799 1923.81 600.589 1933.4 588.325 1920.15C577.93 1908.95 580.856 1892.02 595.371 1878.56C609.206 1865.72 626.608 1863.06 637.389 1874.74C637.913 1875.24 637.866 1875.96 637.36 1876.49L625.6 1887.32C625.094 1887.84 624.424 1887.88 623.851 1887.29C619.539 1882.62 612.816 1882.57 605.342 1889.43C598.511 1895.8 597.464 1902.98 600.917 1906.76C605.516 1911.73 613.377 1908.35 622.074 1903.21C635.151 1895.66 651.432 1886.26 663.883 1899.62C674.141 1910.79 670.738 1929.5 655.458 1943.63C640.561 1957.43 621.609 1960.4 609.918 1947.75Z" fill={fill1}/>
    <path d="M778.05 1836.37L771.31 1827.09C763.698 1816.71 768.541 1804.1 773.265 1792.48C777.559 1781.92 781.384 1771.74 775.768 1764.01C772.398 1759.38 766.79 1756.26 760.522 1760.81C753.437 1765.94 753.949 1772.22 758.342 1778.23C758.779 1778.79 758.645 1779.56 758.089 1779.99L745.256 1789.37C744.7 1789.8 744.03 1789.84 743.63 1789.15C734.308 1776.4 735.652 1760.73 751.786 1748.88C765.089 1739.12 781.817 1740.89 789.879 1752.06C801.248 1767.55 794.117 1784.6 788.468 1797.21C785.066 1804.72 782.576 1811.01 784.972 1814.36L816.907 1791.07C817.463 1790.63 818.233 1790.77 818.669 1791.32L825.895 1801.24C826.332 1801.79 826.199 1802.56 825.642 1803L779.762 1836.53C779.256 1837.06 778.536 1837.01 778.05 1836.37Z" fill={fill1}/>
    <path d="M808.297 1762.55C794.234 1741 793.431 1719.41 814.519 1705.73C835.259 1692.25 854.812 1701.52 868.837 1723.21C882.713 1744.65 883.703 1766.36 862.702 1779.99C841.875 1793.51 822.459 1784.28 808.297 1762.55ZM830.631 1761.44C832.164 1763.09 833.636 1762.24 833.502 1760.01L831.293 1717.98C831.199 1716.42 830.376 1715.4 828.777 1715.63C826.645 1715.93 824.676 1716.72 822.561 1718.06C811.913 1725.02 814.102 1737.61 823.818 1752.44C825.955 1755.94 828.188 1758.81 830.631 1761.44ZM848.977 1770.01C850.922 1769.58 852.804 1768.84 854.746 1767.61C865.221 1760.75 863.032 1748.16 853.402 1733.28C851.329 1730.09 849.283 1727.34 847.077 1724.92C845.544 1723.27 844.021 1724.03 844.155 1726.26L846.337 1767.84C846.381 1769.32 847.428 1770.33 848.977 1770.01Z" fill={fill1}/>
    <path d="M897.013 1755.21L891.4 1745.29C885.037 1734.07 891.309 1722.13 897.326 1711.15C902.863 1701.14 907.794 1691.48 903.08 1683.12C900.18 1678.1 895.029 1674.37 888.274 1678.27C880.689 1682.54 880.395 1688.83 884.058 1695.37C884.408 1695.98 884.225 1696.66 883.619 1697.01L869.799 1704.88C869.193 1705.23 868.56 1705.13 868.21 1704.52C860.446 1690.88 863.59 1675.32 881.01 1665.49C895.35 1657.33 911.752 1661.14 918.515 1673.05C927.942 1689.78 919.009 1705.91 911.88 1717.76C907.644 1724.83 904.371 1730.76 906.471 1734.39L940.829 1714.9C941.436 1714.55 942.119 1714.74 942.469 1715.34L948.532 1726.05C948.882 1726.65 948.699 1727.33 948.093 1727.68L898.653 1755.65C898.046 1756 897.363 1755.82 897.013 1755.21Z" fill={fill1}/>
    <path d="M940.827 1708.9L937.41 1701.98C937.16 1701.55 937.12 1700.88 937.13 1700.3L946.233 1633.27C946.33 1632.63 946.663 1632.21 947.232 1632L963.99 1623.82C964.647 1623.56 965.33 1623.74 965.593 1624.4L988.139 1670.85L1000.11 1664.98C1000.76 1664.71 1001.45 1664.9 1001.71 1665.55L1007.08 1676.66C1007.34 1677.31 1007.16 1678 1006.5 1678.26L994.536 1684.13L1002.1 1699.62C1002.36 1700.28 1002.18 1700.96 1001.52 1701.23L987.115 1708.27C986.458 1708.54 985.775 1708.36 985.512 1707.7L977.951 1692.2L942.257 1709.58C941.824 1709.83 941.141 1709.65 940.827 1708.9ZM971.727 1678.82L958.182 1650.96L953.274 1687.86L971.727 1678.82Z" fill={fill1}/>
    <path d="M701.665 1855.06L686.967 1858L692.4 1844.01L689.407 1829.23L703.447 1834.75L718.145 1831.81L712.712 1845.8L715.655 1860.49L701.665 1855.06Z" fill={fill1}/>
    <path d="M1057.78 1639.07L1042.91 1636.92L1052.71 1625.49L1054.77 1610.67L1066.2 1620.46L1081.07 1622.62L1071.22 1633.96L1069.21 1648.87L1057.78 1639.07Z" fill={fill1}/>
    </g>
    <defs>
    <clipPath id="clip0_5633_1756">
    <rect width="1080" height="1920" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  };

  export default Bg