export const getIntroAnimation1 = function (tl: gsap.core.Timeline, rings: any[]) {
  tl.to(".part-2", { display: "none", opacity: 0, duration: 0 });

  for (let i = 0; i < rings.length; i++) {
    tl.set(`.c-ring-${i}`, {
      rotate: 0 + i * 45,
      scale: 0.1,
      opacity: 0,
    });
  }

  tl.to(".c-ring", {
    scale: 1.7,
    duration: 2,
    opacity: 1,
    ease: "expo.out",
  });

  for (let i = 0; i < rings.length; i++) {
    tl.to(
      `.c-ring-${i}`,
      {
        rotate: 360 * 2,
        duration: 2,
        ease: "expo.out",
      },
      "<"
    );
  }
  tl.to(".carret-text-1", { text: "Until next time!", duration: 0.6, delay: 1.5 }, "<");

  tl.to(
    `.trinkets`,
    {
      duration: 0.5,
      delay: 0.8,
      ease: "power1.out",
      opacity: 1,
    },
    "<"
  );

  tl.to(".c-ring", {
    scale: 0,
    duration: 2,
    opacity: 1,
    ease: "expo.in",
  });

  for (let i = 0; i < rings.length; i++) {
    tl.to(
      `.c-ring-${i}`,
      {
        rotate: 360 * 6,
        duration: 2,
        ease: "expo.in",
      },
      "<"
    );
  }

  tl.to(
    `.trinkets`,
    {
      duration: 0.5,
      delay: 0.8,
      ease: "power1.out",
      opacity: 0,
    },
    "<"
  );

  tl.to(".carret-text-1", { text: "", duration: 0.6, delay: 0.7 }, "<");
};
