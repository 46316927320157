import Confetti from "@/assets/icons/wrapped/trinkets-2.svg";
import { COUNTRIES, YearWrappedResponse } from "@/assets/interfaces";
import { getCardAnimation1, getCardAnimation2 } from "@/assets/js/animations/wrapped/store-orders";
import { COUNTRY_CURRENCY_MAP, CURRENCY_COUNTRY_MAP } from "@/assets/js/utils/constants";
import { toKobo, toNaira } from "@/assets/js/utils/utils";
import { useListener } from "@/components/hooks/useListener";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, { useEffect, useMemo, useRef } from "react";

gsap.registerPlugin(useGSAP);

interface Props extends YearWrappedResponse {}
const StorePaymentsCard: React.FC<Props> = ({
  total_payments_count,
  total_payments_volume,
  store,
  store_currency,
  is_shared,
}) => {
  const container = useRef();
  const videoRef = useRef<HTMLVideoElement>();
  const tlRef = useRef<gsap.core.Timeline>();

  useEffect(() => {
    if (videoRef) {
      videoRef.current.play();
    }
  }, []);

  useListener("toggle-play-gsap", () => {
    if (tlRef.current) {
      if (tlRef.current.paused()) {
        tlRef.current.play();
        videoRef.current.play();
      } else {
        tlRef.current.pause();
        videoRef.current.pause();
      }
    }
  });

  useGSAP(
    () => {
      const tl = gsap.timeline();
      tlRef.current = tl;

      tl.set(".row", { y: "20", opacity: 0 });
      tl.set(".text-card", { y: "50", opacity: 0 });
      tl.set(".trinkets", { opacity: 0 });
      tl.set(".card-illustration", { opacity: 0 });

      tl.set(".part-2", { display: "none" });
      tl.set(".part-2-text-1", { y: "-20", opacity: 0 });
      tl.set(".part-2-stat", { scale: 0, opacity: 0 });
      tl.set(".part-2-text-2", { y: "20", opacity: 0 });
      tl.set(".part-2-text-3", { y: "20", opacity: 0 });

      getCardAnimation1(tl);
      getCardAnimation2(tl);
    },
    { scope: container }
  );

  const country = CURRENCY_COUNTRY_MAP[store_currency];

  const showAlt = useMemo(() => {
    if (country === COUNTRIES.NG) return total_payments_volume < toKobo(1_000_000);
    else if (country === COUNTRIES.GH) return total_payments_volume < 10_000;
  }, [country]);

  const textRows1 = [
    <span key={0}>{is_shared ? "I" : "You"} made big </span>,
    <span key={1}>money moves</span>,
    <span key={2}>in 2024!</span>,
  ];

  return (
    <div ref={container} className="relative flex flex-col items-center justify-start w-full h-full bg-[#38D771]">
      <div className="w-full absolute left-0 top-0 h-full">
        <div className="w-full h-full absolute z-[2]"></div>
        <video
          ref={videoRef}
          autoPlay
          controls={false}
          playsInline={true}
          style={{ pointerEvents: "none" }}
          className="w-full h-full object-cover"
          src="https://catlog-s3.s3.eu-west-2.amazonaws.com/year+wrapped/videos/payments_processed.mp4"
        />
      </div>

      <div className="text-center absolute text-white left-0 right-0 mx-auto w-full h-[65%] p-10  leading-tight ">
        <div className="w-full h-full text-2xl sm:text-3xl overflow-hidden text-card ">
          <Confetti className="w-[98%] abs-center trinkets" />

          <div className="part-1 w-full h-full flex flex-col items-center justify-center font-semibold">
            {textRows1.map((r, i) => (
              <h3 key={i} className={`row row-${i + 1}`}>
                {r}
              </h3>
            ))}
          </div>

          <div className="part-2 w-full h-full flex flex-col items-center justify-center">
            <div className="flex items-center flex-col gap-2.5 ">
              <h3 className="text-xl font-semibold tracking-tight part-2-text-1 ">
                {is_shared ? "I" : "You"} processed
              </h3>
              <div className="bg-[#D9FFE6] p-2.5 rounded-xl part-2-stat">
                <h3 className="text-[#38D771] font-bold ">
                  {COUNTRY_CURRENCY_MAP[country]}{" "}
                  {country === COUNTRIES.NG
                    ? toNaira(total_payments_volume).toLocaleString()
                    : toNaira(total_payments_volume).toLocaleString()}
                </h3>
              </div>
              <h3 className="text-xl font-semibold tracking-tight part-2-text-2">
                from {total_payments_count} payments in 2024!
              </h3>
            </div>
            <h3 className="mt-10 text-base part-2-text-3">
              {is_shared ? (
                <span>
                  How much do you think <br /> I'll make this year? 👀
                </span>
              ) : !showAlt ? (
                <span>
                  You crushed it. Keep <br /> the momentum going!
                </span>
              ) : (
                <span>
                  Bigger wins ahead <br /> this year—keep going!{" "}
                </span>
              )}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StorePaymentsCard;
