import { paramsFromObject } from "@/assets/js/utils/functions";
import { StoreInterface } from "@/assets/interfaces";
import {
  CreateStoreParams,
  GetStoreByIdParams,
  CreateStoreCategoriesParams,
  GetStoreCategoriesParams,
  DeleteStoreCategoryParams,
  GetStoreBySlugParams,
  UpdateStoreParams,
  UpdateStoreLinkParams,
  UpdateDeliveryAreasParams,
  GetDeliveryAreasParams,
  UpdateCheckoutChannelsParams,
  CreateInviteParams,
  AcceptInviteParams,
  DeleteInviteParams,
  RemoveTeamMemberParams,
  UpdateTeamMemberParams,
  UpdateDirectCheckoutParams,
  UpdateStorePaymentMethodsParams,
  UpdateCurrenciesParams,
  UpdateStoreMaintenanceModeParams,
  UpdateSecurityPinParams,
  GetInstagramAccessTokenParams,
  GetInstagramMediaParams,
  GetInstagramAlbumMediaParams,
  GetMultipleInstagramAlbumMediaParams,
  GetProductTypesParams,
  UpdateBusinessCategoryParams,
  UpdateStoreColorParams,
  CreateBranchesParams,
  UpdateBranchesParams,
  UpdateChowdeckConfigParams,
  UpdateStoreAdditionalDetailsParams,
} from "./interfaces/stores.interface";

import { request } from "./utils";

const CreateStore = (data: CreateStoreParams) => {
  return request("stores", "post", { data });
};

const GetStoreById = (data: GetStoreByIdParams) => {
  return request(`stores/${data.id}`, "get");
};

const UpdateStoreDetails = (data: UpdateStoreParams) => {
  const storeId = data.id;
  const dataCopy = { ...data };
  delete dataCopy.id;

  return request(`stores/${storeId}`, "put", { data: dataCopy });
};

const CreateStoreCategories = (data: CreateStoreCategoriesParams) => {
  return request(`stores/${data.id}/categories`, "post", { data: data.categories });
};

const GetStoreCategories = (data: GetStoreCategoriesParams) => {
  return request(`stores/${data.id}/categories`, "get");
};

const DeleteStoreCategory = (data: DeleteStoreCategoryParams) => {
  return request(`stores/${data.id}/categories/${data.category_id}`, "delete");
};

const GetStoreBySlug = (data: GetStoreBySlugParams) => {
  return request(`stores/public/${data.slug}`, "get");
};

const UpdateStoreLink = (data: UpdateStoreLinkParams) => {
  const storeId = data.store;
  const dataCopy = { ...data };
  delete dataCopy.store;

  return request(`stores/${storeId}/slug`, "put", { data: dataCopy });
};

//delivery areas
const GetDeliveryAreas = (data: GetDeliveryAreasParams) => {
  return request(`stores/${data.store}/delivery-areas`, "get");
};

const UpdateDeliveryAreas = (data: UpdateDeliveryAreasParams) => {
  return request(`stores/${data.store}/delivery-areas`, "put", { data: data.data });
};

const UpdateCheckoutChannels = (data: UpdateCheckoutChannelsParams) => {
  return request(`stores/${data.store}/checkout-channels`, "put", { data: data.data });
};

//INVITES
const CreateInvite = (data: CreateInviteParams) => {
  return request(`stores/invites`, "post", { data });
};

const GetInvite = (data: { id: string }) => {
  return request(`stores/invites/${data.id}`, "get");
};

const AcceptInvite = (data: AcceptInviteParams) => {
  const id = data.id;
  const dataCopy = { ...data };
  delete dataCopy.id;

  return request(`stores/invites/${id}`, "put", { data: dataCopy });
};

const DeleteInvite = (data: DeleteInviteParams) => {
  return request(`stores/invites/${data.id}`, "delete");
};

//TEAMS
const GetTeamMembers = () => {
  return request("stores/teams/", "get");
};

const UpdateTeamMember = (data: UpdateTeamMemberParams) => {
  return request(`stores/teams/`, "put", { data: data });
};

const RemoveTeamMember = (data: RemoveTeamMemberParams) => {
  return request(`stores/teams/${data.id}`, "delete");
};

const UpdateDirectCheckout = (data: UpdateDirectCheckoutParams) => {
  const storeId = data.id;
  const dataCopy = { ...data };
  delete dataCopy.id;

  return request(`stores/${storeId}/direct-checkout`, "put", { data: dataCopy });
};

const UpdateStorePaymentMethods = (data: UpdateStorePaymentMethodsParams) => {
  const storeId = data.id;
  const dataCopy = { ...data };
  delete dataCopy.id;

  return request(`stores/${storeId}/payment-methods`, "put", { data: dataCopy });
};

const UpdateStoreCurrencies = (data: UpdateCurrenciesParams) => {
  const storeId = data.id;
  return request(`stores/${storeId}/currencies`, "put", { data: data.data });
};

const UpdateStoreMaintenanceMode = (data: UpdateStoreMaintenanceModeParams) => {
  const storeId = data.id;
  return request(`stores/${storeId}/maintenance-mode`, "put", { data: { state: data.state } });
};

const UpdateSecurityPin = (data: UpdateSecurityPinParams) => {
  const storeId = data.id;
  delete data.id;
  return request(`stores/${storeId}/security-pin`, "put", { data });
};

const GenerateInstagramAccessToken = (data: GetInstagramAccessTokenParams) => {
  const params = paramsFromObject(data);
  return request(`stores/ig/access-token?${params}`, "get");
};

const DisconnectInstagram = () => {
  return request(`stores/ig/disconnect`, "get");
};

const GetInstagramMedia = (data: GetInstagramMediaParams) => {
  const params = paramsFromObject(data);
  return request(`stores/ig/media?${params}`, "get");
};

const GetInstagramUser = () => {
  return request(`stores/ig/user`, "get");
};

const GetInstagramAlbumMedia = (data: GetInstagramAlbumMediaParams) => {
  return request(`stores/ig/album-media/${data.media_id}`, "get");
};

const GetMultipleInstagramAlbumMedia = (data: GetMultipleInstagramAlbumMediaParams) => {
  return request(`stores/ig/albums-media`, "post", { data });
};

const CheckInstagramToken = () => {
  return request(`stores/ig/check-token/`, "get");
};

const GetBusinessCategories = () => {
  return request("stores/business-categories", "get");
};

const GetProductTypes = (data: GetProductTypesParams) => {
  return request(`stores/business-categories/${data.category}`, "get");
};

const UpdateBusinessCategorization = (data: UpdateBusinessCategoryParams) => {
  return request(`stores/${data.id}/categorization`, "put", { data: data.business_category });
};

const UpdateStoreAdditionalDetails = (data: UpdateStoreAdditionalDetailsParams) => {
  return request(`stores/additional-details`, "put", { data: data.additional_details });
};

const UpdateStoreColor = (data: UpdateStoreColorParams) => {
  const { id, ...rest } = data;

  return request(`stores/${id}/color`, "put", { data: rest });
};

// const GetChowdeckItems = () => {
//   return request(`stores/chowdeck/menu-items`, "get");
// };

const GetChowdeckCategories = () => {
  return request(`stores/chowdeck/menu-categories`, "get");
};

const GetBranches = (data: { id: string }) => {
  return request(`stores/branches/${data.id}`, "get");
};

const CreateOrUpdateBranches = (data: UpdateBranchesParams) => {
  const { id, ...rest } = data;
  return id
    ? request(`stores/branches/${id}`, "post", { data: rest })
    : request(`stores/branches`, "post", { data: rest });
};

const DeleteBranches = (data: { id: string }) => {
  return request(`stores/branches/${data.id}`, "delete");
};

const GetMenuImage = (data: { storeId: string }) => {
  return request(`stores/${data.storeId}/menu-image`, "put");
};

const UpdateChowdeckConfiguration = (data: UpdateChowdeckConfigParams) => {
  const { storeId, ...rest } = data;
  return request(`stores/${storeId}/chowdeck-config`, "put", { data: rest });
};

const UpdateAutoCheckInConfiguration = (data) => {
  const { store_id, ...rest } = data;
  return request(`stores/${store_id}/auto-check-in`, "put", { data: rest });
};

const GetStoreWrappedData = (data: { store_slug: string }) => {
  return request(`store/year-wrap`, "get");
};
const GetStoreWrappedDataPublic = (data: { store_slug: string }) => {
  const { store_slug } = data;
  return request(`store/year-wrap/${store_slug}`, "get");
};

const GetWrappedImages = (data: { store_slug: string; cards: string }) => {
  const { store_slug, cards } = data;
  return request(`utils/get-wrapped-screenshots/${store_slug}?cards=${cards}`, "get");
};



export {
  CreateStore,
  GetStoreById,
  CreateStoreCategories,
  GetStoreCategories,
  DeleteStoreCategory,
  GetStoreBySlug,
  UpdateStoreDetails,
  UpdateStoreLink,
  GetDeliveryAreas,
  UpdateDeliveryAreas,
  UpdateCheckoutChannels,
  CreateInvite,
  GetInvite,
  AcceptInvite,
  GetTeamMembers,
  RemoveTeamMember,
  DeleteInvite,
  UpdateTeamMember,
  UpdateDirectCheckout,
  UpdateStorePaymentMethods,
  UpdateStoreCurrencies,
  UpdateStoreMaintenanceMode,
  UpdateSecurityPin,
  GenerateInstagramAccessToken,
  DisconnectInstagram,
  GetInstagramAlbumMedia,
  CheckInstagramToken,
  GetInstagramMedia,
  GetMultipleInstagramAlbumMedia,
  GetBusinessCategories,
  GetProductTypes,
  UpdateBusinessCategorization,
  UpdateStoreColor,
  // GetChowdeckItems,
  GetChowdeckCategories,
  GetBranches,
  CreateOrUpdateBranches,
  DeleteBranches,
  GetMenuImage,
  UpdateChowdeckConfiguration,
  GetInstagramUser,
  UpdateAutoCheckInConfiguration,
  UpdateStoreAdditionalDetails,
  GetStoreWrappedData,
  GetStoreWrappedDataPublic,
  GetWrappedImages,
};
