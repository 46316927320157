import Logo from "@/assets/icons/logo.svg";
import { YearWrappedResponse } from "@/assets/interfaces";
import { getCardAnimation1 } from "@/assets/js/animations/wrapped/year-review";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, { useRef } from "react";
import Confetti from "react-confetti";
import Bg from "./big-moves-bg";

gsap.registerPlugin(useGSAP);

interface Props extends YearWrappedResponse {}
const YearMetaphor: React.FC<Props> = ({ metaphor, is_shared }) => {
  const container = useRef();

  useGSAP(
    () => {
      const tl = gsap.timeline();
      tl.set(".part-1", { y: "50", opacity: 0 });
      tl.set(".part-1-text-1", { y: "-20", opacity: 0 });
      tl.set(".part-1-text-2", { y: "40", opacity: 0 });
      tl.set(".part-1-text-3", { y: "-40", opacity: 0 });
      tl.set(".part-2", { display: "none" });
      tl.set(".card-image", { y: "20", scale: 0, opacity: 0 });

      getCardAnimation1(tl);
    },
    { scope: container }
  );

  const reviewType = reviewTypes[metaphor.toLowerCase()];

  return (
    <div
      ref={container}
      className="relative flex flex-col items-start  px-5 justify-start pt-10 w-full h-full bg-[#5644B3]"
    >
      <div className="w-full absolute left-0 top-0 h-full">{Bg(reviewType.fill1, reviewType.fill2)}</div>

      <div
        style={{ backgroundColor: reviewType.fill2, color: reviewType.textColor }}
        className="part-1 pt-5 z-10 text-center border border-white border-opacity-20 rounded-[40px] h-[90%] w-full"
      >
        <Confetti numberOfPieces={1000} recycle={false} />
        <h5 className="part-1-text-1 font-semibold text-sm">
          {is_shared ? `I AM ${reviewType.article}` : `YOU ARE ${reviewType.article}`}
        </h5>
        <img src={reviewType.image} className="card-image mt-10 h-[50%] mx-auto" alt="" />
        <h2 className="text-5xl sm:text-6xl part-1-text-2 mt-5"> {reviewType.name} </h2>
        <p className="text-1sm font-display w-[70%] mx-auto mt-5 part-1-text-3">
          {is_shared ? reviewType.sharedDescription : reviewType.description}
        </p>
      </div>

      <div className="flex part-2 left-0 px-5 items-center absolute justify-between w-full bottom-10">
        <Logo className="w-32" />
        <h3 className="font-bold text-white">CATLOG CHRONICLES `24</h3>
      </div>
    </div>
  );
};
export default YearMetaphor;

const reviewTypes = {
  lily: {
    fill1: "#FF9540",
    fill2: "#FD8524",
    textColor: "white",
    name: "Lily",
    image: "https://catlog-s3.s3.eu-west-2.amazonaws.com/year+wrapped/illustrations/lily.png",
    description:
      "You have so much potential, but you’re still in your early stages. Give your business time and energy and you’ll do amazing.",
    sharedDescription:
      "I have so much potential, and with the time and energy I put into my business, I know I’ll achieve amazing things.",
    title: null,
    article: "A",
  },
  bamboo: {
    fill1: "#44E27D",
    fill2: "#38D771",
    textColor: "white",
    name: "Bamboo",
    image: "https://catlog-s3.s3.eu-west-2.amazonaws.com/year+wrapped/illustrations/bamboo.png",
    description: "Your roots are beginning to spread. With a little more push, you’d be unstoppable!",
    sharedDescription: "My roots are beginning to spread. With a little more push, I’d be unstoppable!",
    title: null,
    article: "A",
  },
  "oak tree": {
    fill1: "#6955D1",
    fill2: "#5644B3",
    textColor: "white",
    name: "Oak",
    image: "https://catlog-s3.s3.eu-west-2.amazonaws.com/year+wrapped/illustrations/oak.png",
    description: "Your business has a solid foundation. Go for more this year!",
    sharedDescription: "My business has a solid foundation. I’m going for more this year!",
    title: "YOU ARE AN",
    article: "AN",
  },
  "iroko tree": {
    fill1: "#FFD964",
    fill2: "#FFCE38",
    textColor: "#333333",
    name: "Iroko",
    image: "https://catlog-s3.s3.eu-west-2.amazonaws.com/year+wrapped/illustrations/iroko.png",
    description:
      "You’re a true business tycoon. Other people could learn a thing or two from you. Keep the momentum going!",
    sharedDescription:
      "I'm making great progress, because of your support, there's still so much more to achieve. I'll keep the momentum going!",
    title: "YOU ARE AN",
    article: "AN",
  },
};
