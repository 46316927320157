export const getCardAnimation1 = function (tl: gsap.core.Timeline, type) {
  tl.to(".part-2", { display: "none", opacity: 0, duration: 0 });
  tl.to(".text-card", {
    y: "0",
    delay: 2,
    duration: 0.5,
    ease: "power1.out",
    opacity: 1,
  });

  for (let i = 0; i < 4; i++) {
    tl.to(
      `.row-${i + 1}`,
      {
        y: "0",
        delay: type == 0 ? (i == 2 ? 1.2 : 0.3) : 0.3,
        duration: 0.5,
        ease: "power1.out",
        opacity: 1,
      },
      "<"
    );
  }

  tl.to(
    `.trinkets`,
    {
      duration: 0.5,
      delay: 0.1,
      ease: "power1.out",
      opacity: 1,
    },
    "<"
  );
  for (let i = 0; i < 4; i++) {
    tl.to(
      `.row-${i + 1}`,
      {
        y: "20",
        delay: i == 0 ? 2 : 0.1,
        duration: 0.5,
        ease: "power1.out",
        opacity: 0,
      },
      "<"
    );
  }
  tl.to(".part-1", { display: "none" });
};

export const getCardAnimation2 = function (tl: gsap.core.Timeline, type) {
  tl.to(".part-2", { display: "flex", opacity: 1, duration: 0 });

  if (type == 0) {
    tl.to(
      ".numbers-dial",
      {
        rotate: 360 * 8,
        duration: 4,
        ease: "expo.out",
      },
      "<"
    );
    tl.to(
      `.part-2-footer`,
      {
        y: "0",
        duration: 0.5,
        ease: "power1.out",
        opacity: 1,
        delay: 4,
      },
      "<"
    )
    return;
  }

  for (let i = 0; i < 8; i++) {
    tl.to(
      `.text-pop-${i + 1}`,
      {
        y: "0",
        delay: 0.05,
        duration: 0.8,
        ease: "bounce.out",
        opacity: 1,
        scale: 1,
      },
      "<"
    );
  }

  tl.to(".text-pop", { scale: 2, ease: "power3.out", delay: 0.7, duration: 0.8 }, "<");
  tl.to(".text-pop", { scale: 1, ease: "bounce.out", delay: 1, duration: 1 }, "<");

  for (let i = 0; i < 2; i++) {
    tl.to(
      `.part-2-text-${i + 1}`,
      {
        y: "0",
        delay: i == 0 ? 1 : 0.2,
        duration: 0.5,
        ease: "power1.out",
        opacity: 1,
      },
      "<"
    );
  }

  tl.to(
    `.part-2-footer`,
    {
      y: "0",
      duration: 0.5,
      ease: "power1.out",
      opacity: 1,
      delay: 1.2,
    },
    "<"
  );
};
