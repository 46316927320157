import Confetti from "@/assets/icons/wrapped/trinkets.svg";
import { YearWrappedResponse } from "@/assets/interfaces";
import { getCardAnimation1, getCardAnimation2 } from "@/assets/js/animations/wrapped/store-visits";
import { useListener } from "@/components/hooks/useListener";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, { useEffect, useRef } from "react";

gsap.registerPlugin(useGSAP);

interface Props extends YearWrappedResponse {}
const StoreVisitsCard: React.FC<Props> = ({ no_of_store_visits, is_shared = false }) => {
  const container = useRef();
  const videoRef = useRef<HTMLVideoElement>();
  const tlRef = useRef<gsap.core.Timeline>();

  useEffect(() => {
    if (videoRef) {
      videoRef.current.play();
    }
  }, []);

  useListener("toggle-play-gsap", () => {
    if (tlRef.current) {
      if (tlRef.current.paused()) {
        tlRef.current.play();
        videoRef.current.play();
      } else {
        tlRef.current.pause();
        videoRef.current.pause();
      }
    }
  });

  useGSAP(
    () => {
      const tl = gsap.timeline();
      tlRef.current = tl;

      tl.set(".row", { y: "20", opacity: 0 }).set(".text-card", { y: "50", opacity: 0 });
      tl.set(".trinkets", { opacity: 0 });
      tl.set(".part-2", { display: "none" });
      tl.set(".card-illustration", { opacity: 0 });

      getCardAnimation1(tl, videoRef.current);
      getCardAnimation2(tl, is_shared);
    },
    { scope: container }
  );

  const showAlt = no_of_store_visits < 1000;

  const textRows1 = getOpeningText(no_of_store_visits, is_shared);
  const textRows2 = getFollowingText(showAlt, is_shared);

  return (
    <div ref={container} className="relative flex flex-col items-center justify-start w-full h-full bg-[#5644B3]">
      <div className="w-full absolute left-0 top-0 h-full">
        <div className="w-full h-full absolute z-[2]"></div>
        <video
          ref={videoRef}
          autoPlay
          controls={false}
          playsInline={true}
          style={{ pointerEvents: "none" }}
          className="w-full h-full object-cover"
          src="https://catlog-s3.s3.eu-west-2.amazonaws.com/year+wrapped/videos/total_store_visits.mp4"
        />
      </div>
      <div className="text-center absolute left-0 right-0 mx-auto w-full h-[65%] p-10  leading-tight ">
        <div className="w-full h-full text-2xl sm:text-3xl overflow-hidden text-card rounded-[40px] ">
          <Confetti className="w-[100%] abs-center trinkets" />
          <div className="part-1 w-full h-full flex flex-col items-center justify-center">
            {textRows1.map((r, i) => (
              <h3 key={i} className={`text-white font-semibold row row-${i + 1}`}>
                {r}
              </h3>
            ))}
          </div>
          <div className="part-2 w-full h-full flex flex-col items-center justify-center">
            {textRows2.map((r, i) => (
              <h3 key={i} className={`text-white font-semibold row row-part-2-${i + 1}`}>
                {r}
              </h3>
            ))}
            {showAlt && (
              <h3 className="text-sm mt-5 text-white ">
                <span className="carret-text-1 font-medium"></span>
                <br /> <span className="carret-text-2"></span>
              </h3>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const getOpeningText = (no_of_store_visits: number, isShared: boolean) => {
  if (isShared) {
    return [
      <span key={0}>
        In 2024, <span className="text-accent-yellow-300">{no_of_store_visits}</span>
      </span>,
      <span key={1}>people visited</span>,
      <span key={2}>my store</span>,
    ];
  }

  return [
    <span key={0}>
      In 2024, <span className="text-accent-yellow-300">{no_of_store_visits}</span>
    </span>,
    <span key={1}>people visited</span>,
    <span key={2}>your store</span>,
  ];
};

const getFollowingText = (showAlt: boolean, isShared: boolean) => {
  if (isShared) {
    return showAlt
      ? [
          <span key={0}>Seems like my</span>,
          <span key={1}>customers want me </span>,
          <span key={2}>to themselves 🤗</span>,
        ]
      : [
          <span key={0}>My customers</span>,
          <span key={1}>
            <span className="text-accent-yellow-300">love</span> 💛 me! They're
          </span>,
          <span key={2}>the real MVPs!</span>,
        ];
  }

  return showAlt
    ? [
        <span key={0}>Seems like your</span>,
        <span key={1}>customers want you </span>,
        <span key={2}>to themselves 🤗</span>,
      ]
    : [
        <span key={0}>Your customers</span>,
        <span key={1}>
          <span className="text-accent-yellow-300">love</span> 💛 you! How
        </span>,
        <span key={2}>do you do it?</span>,
      ];
};

export default StoreVisitsCard;
